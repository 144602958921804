<template>
    <component :is="layout">
        <router-view/>
    </component>
</template>

<script>
import LoginTemplate from '@/components/Layouts/LoginTemplate.vue'
import FullTemplate from '@/components/Layouts/FullTemplate.vue'
import PresentationTemplate from '@/components/Layouts/PresentationTemplate.vue'
import MainTemplate from '@/components/Layouts/MainTemplate.vue'
import FormTemplate from '@/components/Layouts/FormTemplate.vue'
import SettingsTemplate from '@/components/Layouts/SettingsTemplate.vue'
import SystemTemplate from '@/components/Layouts/SystemTemplate.vue'

export default {
    data() {
        return {
            layout: 'main-template',
            finishApplications: false
        }
    },
    components: {
        MainTemplate,
        FormTemplate,
        SettingsTemplate,
        FullTemplate,
        PresentationTemplate,
        LoginTemplate,
        SystemTemplate
    },
    watch: {
        $route(to, from) {
            if (['not-exist', 'forbbiden', 'not-found'].includes(this.$route.name) && !this.$auth.check()) {
                this.layout = 'presentation-template'
            } else {
                this.layout = this.$route.meta.layout
            }
        }
    },
    mounted() {
        if (['not-exist', 'forbbiden', 'not-found'].includes(this.$route.name) && !this.$auth.check()) {
            this.layout = 'presentation-template'
        } else {
            this.layout = this.$route.meta.layout
        }

        this.$cookies.set('websocket_notifications', 'close')

        var refreshIntervalId = setInterval(() => {
            if(this.finishApplications){
                clearInterval(refreshIntervalId);
            }else{
                this.getApplications()
                this.checkStatus()
            }
        }, 50);
    },
    methods: {
        getApplications(){
            if(this.$auth.check()){
                this.finishApplications = true
                this.$store.dispatch('applications/getApplications');
            }
        },
        checkStatus(){
            if(this.$auth.check() && !this.$auth.user().is_active){
                this.$auth.logout();
            }
        },
    }
}
</script>