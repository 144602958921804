<template>
	<div class="sidebar-settings">
		<div class="top">
			<h1>{{$t('general.menu')}}</h1>
			<div class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close /></div>
		</div>
			
		<div class="settings-content">
			<div class="row">
				<div class="item-menu dashboard" @click.stop="closeModal" v-if="soft_organigram && soft_organigram.is_active">
					<a :href="`${organigram_fe_link}/dashboard`">
						<icon-dashboard class="icon-item"/>
						<span class="text">{{ $t('navbar.dashboard')}}</span>
					</a>
				</div>
				<div class="item-menu users" @click.stop="closeModal" v-if="soft_organigram && soft_organigram.is_active">
					<a :href="`${organigram_fe_link}/users`">
						<icon-people class="icon-item"/>
						<span class="text">{{ $t('navbar.users')}}</span>
					</a>
				</div>
			</div>
			<!-- vartej -->
			<template>
				<div class="header">
					{{ $t('sidebar_tbf.vartej')}}
				</div>
				<div class="row wrap">
					<div class="item-menu" @click.stop="closeModal" v-if="soft_organigram && soft_organigram.is_active">
						<a :href="`${organigram_fe_link}/company`">
							<icon-chart class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.organigram')}}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal" v-if="soft_organigram && soft_organigram.is_active">
						<a :href="`${organigram_fe_link}/vacant-jobs`">
							<icon-vacant-job class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.vacant_jobs')}}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal" v-if="soft_organigram && soft_organigram.is_active">
						<a :href="`${organigram_fe_link}/roles`">
							<icon-roles class="icon-item"/>
							<span class="text">{{ $t('general.roles')}}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<router-link :to="{name: 'procedures' }">
							<icon-procedures class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.procedures')}}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<router-link :to="{name: 'processes' }">
							<icon-processes class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.processes')}}</span>
						</router-link>
					</div>
				</div>
			</template>
			<!-- crestere -->
			<template v-if="soft_objectives && soft_objectives.is_active">
				<div class="header">
					{{ $t('sidebar_tbf.crestere')}}
				</div>
				<div class="row wrap">
					<div class="item-menu" @click.stop="closeModal" v-if="soft_objectives && soft_objectives.is_active">
						<a :href="`${objectives_fe_link}/obiective`">
							<icon-objectives class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.objectives')}}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal" v-if="soft_objectives && soft_objectives.is_active">
						<a :href="`${objectives_fe_link}/master-goals`">
							<icon-master-goals class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.objectives-master-goal')}}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal" v-if="soft_objectives && soft_objectives.is_active">
						<a :href="`${objectives_fe_link}/smart-planner`">
							<icon-smart-planner class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.objectives-smart-planner')}}</span>
						</a>
					</div>
				</div>
			</template>
			<!-- rapoarte -->
			<template>
				<div class="header">
					{{ $t('navbar.reports')}}
				</div>
				<div class="row wrap">
					<div class="item-menu" @click.stop="closeModal" v-if="soft_organigram && soft_organigram.is_active">
						<a :href="`${organigram_fe_link}/reports`">
							<icon-chart class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.responsibilities')}}</span>
						</a>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<router-link :to="{name: 'reports', query: { type: 'processes'} }">
							<icon-processes class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.processes')}}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<router-link :to="{name: 'reports', query: { type: 'procedures'} }">
							<icon-procedures class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.procedures')}}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<router-link :to="{name: 'reports', query: { type: 'improvements'} }">
							<icon-upgrade class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.process_update_requests')}}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal">
						<router-link :to="{name: 'reports', query: { type: 'users'} }">
							<icon-people class="icon-item"/>
							<span class="text">{{ $t('navbar.users')}}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal" v-if="soft_objectives && soft_objectives.is_active">
						<a :href="`${objectives_fe_link}/rapoarte`">
							<icon-promise class="icon-item"/>
							<span class="text">{{ $t('sidebar_tbf.promises')}}</span>
						</a>
					</div>
				</div>
			</template>
			<!-- setari -->
			<template>
				<div class="header">
					{{ $t('sidebar_tbf.settings')}}
				</div>
				<div class="row wrap">
					<div v-if="$auth.user().rights.admins_instance_crud && soft_organigram && soft_organigram.is_active" class="item-menu" @click.stop="closeModal">
						<a :href="`${organigram_fe_link}/settings/work-schedules`">
							<icon-work-schedule class="icon-item"/>
							<span class="text">{{ $t('settings-navbar.work-schedules')}}</span>
						</a>
					</div>
					<div v-if="$auth.user().rights.admins_instance_crud && soft_organigram && soft_organigram.is_active" class="item-menu" @click.stop="closeModal">
						<a :href="`${organigram_fe_link}/settings/departments`">
							<icon-department class="icon-item"/>
							<span class="text">{{ $t('settings-navbar.departments')}}</span>
						</a>
					</div>
					<div v-if="$auth.user().rights.admins_instance_crud && soft_organigram && soft_organigram.is_active" class="item-menu" @click.stop="closeModal">
						<a :href="`${organigram_fe_link}/settings/workstations`">
							<icon-building class="icon-item"/>
							<span class="text">{{ $t('settings-navbar.workstations')}}</span>
						</a>
					</div>
					<div v-if="$auth.user().rights.admins_instance_crud && soft_organigram && soft_organigram.is_active" class="item-menu" @click.stop="closeModal">
						<a :href="`${organigram_fe_link}/settings/skills`">
							<icon-skill class="icon-item"/>
							<span class="text">{{ $t('settings-navbar.skills')}}</span>
						</a>
					</div>
					<div v-if="$auth.user().rights.admins_instance_crud && soft_organigram && soft_organigram.is_active" class="item-menu" @click.stop="closeModal">
						<a :href="`${organigram_fe_link}/settings/holidays`">
							<icon-calendar class="icon-item"/>
							<span class="text">{{ $t('settings-navbar.holidays')}}</span>
						</a>
					</div>
					<div v-if="$auth.user().rights.admins_instance_crud && soft_organigram && soft_organigram.is_active" class="item-menu" @click.stop="closeModal">
						<a :href="`${organigram_fe_link}/settings/company`">
							<icon-about-company class="icon-item"/>
							<span class="text">{{ $t('settings-navbar.company')}}</span>
						</a>
					</div>
					<div v-if="soft_procedures" class="item-menu" @click.stop="closeModal">
						<router-link :to="{name: 'settings-categories'}">
							<icon-box-archive class="icon-item"/>
							<span class="text">{{ $t('settings-navbar.categories')}}</span>
						</router-link>
					</div>
					<div class="item-menu" @click.stop="closeModal" v-if="$auth.check() && $auth.user().is_entrepreneur && !$auth.user().free_account && soft_organigram && soft_organigram.is_active">
						<a :href="`${organigram_fe_link}/settings/subscription`">
							<icon-wallet class="icon-item"/>
							<span class="text">{{$t('settings-navbar.subscriptions')}}</span>
						</a>
					</div>
					<div class="item-menu" v-if="soft_organigram && soft_organigram.is_active" @click.stop="closeModal">
						<a :href="`${organigram_fe_link}/settings/profile`">
							<icon-user class="icon-item"/>
							<span class="text">{{ $t('settings-navbar.profile')}}</span>
						</a>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import IconPeople from '../Icons/People'
	import IconDashboard from '../Icons/Dashboard'
	import IconChart from '../Icons/Chart'
	import IconVacantJob from '../Icons/VacantJob'
	import IconRoles from '../Icons/Tags'
	import IconProcedures from '../Icons/Procedures'
	import IconProcesses from '../Icons/Processes'
	import IconObjectives from '../Icons/Objectives'
	import IconMasterGoals from '../Icons/MasterGoals'
	import IconSmartPlanner from '../Icons/SmartPlanner'
	import IconUpgrade from '../Icons/Upgrade'
	import IconPromise from '../Icons/Promise'
	import IconWorkSchedule from '../Icons/WorkSchedule'
	import IconDepartment from '../Icons/Department'
	import IconBuilding from '../Icons/Building'
	import IconSkill from '../Icons/Skill'
	import IconCalendar from '../Icons/Calendar'
	import IconBoxArchive from '../Icons/BoxArchive'
	import IconAboutCompany from '../Icons/AboutCompany'
	import IconUser from '../Icons/User'
	import IconGears from '../Icons/Gears'
	import IconWallet from '../Icons/Wallet'

	export default {
		data() {
			return {
				objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK,
				organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK,
				procedures_fe_link: process.env.VUE_APP_PROCEDURES_FE_LINK
			};
		},
		props:{

		},
		components: {
			IconClose,
			IconPeople,
			IconDashboard,
			IconChart,
			IconVacantJob,
			IconRoles,
			IconProcedures,
			IconProcesses,
			IconObjectives,
			IconMasterGoals,
			IconSmartPlanner,
			IconUpgrade,
			IconPromise,
			IconWorkSchedule,
			IconDepartment,
			IconBuilding,
			IconSkill,
			IconCalendar,
			IconBoxArchive,
			IconAboutCompany,
			IconUser,
			IconGears,
			IconWallet
		},
		async mounted() {

		},
		computed: {
			soft_procedures() {
				return this.$store.getters['applications/getApplication']('Proceduri');
			},
			soft_objectives() {
				return this.$store.getters['applications/getApplication']('Obiective');
			},
			soft_organigram() {
				return this.$store.getters['applications/getApplication']('Organigrama');
			}
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
		}
	};
</script>