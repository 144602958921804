import Vue       from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
    return () => import(`../components/${view}.vue`);
}

const routes = [
	{ path: '/login', name: 'login', component: loadView('Auth/Login'), meta: { auth: false, layout: 'login-template'}},
	// { path: '/register', name: 'register', component: loadView('Auth/Register'), meta: { auth: false, layout: 'login-template'}},
	{ path: '/password/forgot', name: 'password-forgot', component: loadView('Auth/ForgotPassword'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/password/forgot-success', name: 'password-forgot-success', component: loadView('Auth/ForgotPasswordSuccess'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/reset-password/:token', name: 'password-reset', component: loadView('Auth/ResetPassword'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/reset-password-success', name: 'password-reset-success', component: loadView('Auth/ResetPasswordSuccess'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/register-success', name: 'register-success', component: loadView('Auth/RegisterSuccess'), meta: { auth: true, layout: 'login-template'}},
	
	{ path: '/payment', name: 'payment', component: loadView('Auth/Register'), meta: { auth: true, layout: 'login-template' }},

	{ path: '/', name: 'dashboard', component: loadView('Pages/Procedures/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template'}},

	{ path: '/processes', name: 'processes', component: loadView('Pages/Processes/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' }},
	{ path: '/process/create', name: 'process-create', component: loadView('Pages/Processes/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/process/edit/:slug', name: 'process-edit', component: loadView('Pages/Processes/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' }},
	{ path: '/process/:slug', name: 'process-show', component: loadView('Pages/Processes/Show'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'full-template' } },
	{ path: '/process/:slug/improvements', name: 'process-improvements', component: loadView('Pages/Procedures/Improvements'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' } },
	
	{ path: '/procedures', name: 'procedures', component: loadView('Pages/Procedures/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' }},
	{ path: '/procedure/create', name: 'procedure-create', component: loadView('Pages/Procedures/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/procedure/:slug', name: 'procedure-show', component: loadView('Pages/Procedures/Show'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' } },
	{ path: '/procedure/:slug/history', name: 'procedure-history', component: loadView('Pages/Procedures/Version'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/procedure/:slug/improvements', name: 'procedure-improvements', component: loadView('Pages/Procedures/Improvements'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' } },
	{ path: '/procedure/edit/:slug', name: 'procedure-edit', component: loadView('Pages/Procedures/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' }},

	// { path: '/users', name: 'users', component: loadView('Pages/Users/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template'}},
	// { path: '/users/create', name: 'user-create', component: loadView('Pages/Users/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	// { path: '/users/edit/:slug', name: 'user-edit', component: loadView('Pages/Users/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	// { path: '/users/:slug', name: 'user-show', component: loadView('Pages/Users/Show'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' } },
	
	{ path: '/settings/categories', name: 'settings-categories', component: loadView('Pages/Settings/Categories'), meta: { auth: true, layout: 'settings-template' } },
	// { path: '/settings/subscription', name: 'settings-subscription', component: loadView('Pages/Settings/Subscriptions'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template' } },
	// { path: '/settings/roles', name: 'settings-roles', component: loadView('Pages/Settings/Roles'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template' } },
	// { path: '/settings/profile', name: 'settings-profile', component: loadView('Pages/Settings/Profile'), meta: { auth: true, layout: 'settings-template' } },
	// { path: '/settings/notifications', name: 'settings-notifications', component: loadView('Pages/Settings/Notifications'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template' } },

	// { path: '/software-updates', name: 'software-updates', component: loadView('Pages/System/Updates'), meta: { auth: true, layout: 'system-template'}},
	// { path: '/software-updates/:id', name: 'software-updates-show', component: loadView('Pages/System/Updates'), meta: { auth: true, layout: 'system-template'}},
	// { path: '/work-in-progress', name: 'work-in-progress', component: loadView('Pages/System/WorkInProgress'), meta: { auth: true, layout: 'system-template'}},
	// { path: '/work-in-progress/:id', name: 'work-in-progress-show', component: loadView('Pages/System/WorkInProgress'), meta: { auth: true, layout: 'system-template'}},
	
	{ path: '/reports', name: 'reports', component: loadView('Pages/Reports/Index'), meta: { auth: true, layout: 'main-template' } },

	{ path: '/403', name: 'forbbiden', component: loadView('Pages/Forbbiden'), meta: { auth: true, layout: 'main-template' } },
	{ path: '/404', name: 'not-found', component: loadView('Pages/NotFound'), meta: { auth: undefined, layout: 'main-template' } },
	{ path: '*', name: 'not-exist', component: loadView('Pages/NotFound'), meta: { auth: undefined, layout: 'main-template' } }
]

Vue.router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
	linkActiveClass: 'active-link'
})

export default Vue.router;