<template>
	<div class="container-content-modal loop_step_modal" v-if="loaded">
		<div class="subtitle">{{ $t('processes.loop_selected') }}</div>
		<div class="title">{{ `${$t('processes.org_step', {n: parseData.temporarySelectedParentLoop.unique_id})}. ${parseData.temporarySelectedParentLoop.name}` }}</div>
		<div class="form-section">
			<div class="input-group w-100">
				<div class="label-header">
					<label class="label">{{ $t('general.choose-color') }}</label>
				</div>
				<div class="radio-colors">
					<div v-for="color in colors" class="box-colors">
						<div class="tag-color" v-bind:class="[ color ? `${color}` : '', {selected: selectedColor == color}]" @click="selectColor(color)">
							<span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-submit">
			<button class="btn-tbf grey center" @click="closeModal">
				<div class="loader"></div>
				<div class="text">{{$t('general.cancel')}}</div>
			</button>
			<button id="buttonNextStep" class="btn-tbf blue center" @click="saveStep">
				<div class="loader-spin white" v-show="loadingSave"></div>
				<div class="text" v-show="!loadingSave">{{ actionEdit ? $t('general.update') : $t('process-chart.modal.add-loop') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import { required } from 'vuelidate/lib/validators'
	import IconCheck from "../../Icons/Check"

	export default {
		data() {
			return {
				loaded: false,
				loadingSave: false,
				actionEdit: false,
				parseData: {},
                selectedColor: '',
				colors: [
					"grey",
					"purple",
					"blue",
					"green",
					"red",
					"yellow",
				]
			}
		},
		props:{
			data: String
		},
		components: {
			IconCheck
		},
		mounted(){
			this.parseData = JSON.parse(this.data)

			setTimeout(()=>{
				this.loaded = true
			}, 200)
		},
		methods: {
			closeModal(){
				this.$emit('close_modal')
			},
			selectColor(colorId){
				this.selectedColor = colorId
			},
			saveStep(){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true
				this.loadingSave = true

				if(this.actionEdit){
					var objSave = {...this.parseData}
					objSave.name = this.blocText;
					this.$emit('store_step', objSave)
				}else{
					this.$emit('store_step', {type: 'loop', name: `${this.$t('processes.org_step', {n: this.parseData.temporarySelectedParentLoop.unique_id})}. ${this.parseData.temporarySelectedParentLoop.name}`, color: this.selectedColor, selectedParentLoop: this.parseData.temporarySelectedParentLoop})
				}
			}
		}
	}
</script>

<style lang="scss">
	.loop_step_modal{
		.subtitle{
			font-size: 14px;
			margin-bottom: 5px;
		}
		.title{
			font-size: 14px;
			font-weight: 500;
		}
	}
</style>