<template>
	<div class="modal-tbf modal-add-step">
		<div class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">
					{{ $t('enhancement.title') }}
				</div>
				<div class="actions">
					<button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t('enhancement.subject') }}*</label>
							</div>
							<div class="input-box" v-bind:class="{has_error: $v.body.$error}">
								<div class="icon-right" v-if="body != ''" @click.stop="body = ''"><icon-close class="icon-clear" /></div>
								<textarea id="subjectEnhancement" v-model="body" rows="5"></textarea>
							</div>
						</div>
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t('enhancement.attachments') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-file /></div>
								<input id="files-btn" type="file" multiple hidden v-on:change="onFilesChange">
								<label for="files-btn" class="label-file-btn">
									<div class="upload-text">{{ $t('enhancement.attach_files') }}</div>
									<div class="selected-files" v-if="filesName.length">- {{ filesName.length == 1 ? filesName[0] : $t('enhancement.more_files', {n : filesName.length}) }}</div>
								</label>
							</div>
						</div>
					</div>
					<div class="form-submit">
						<button class="btn-tbf grey center" @click="closeModal">
							<div class="loader"></div>
							<div class="text">{{$t('general.cancel')}}</div>
						</button>
						<button id="buttonSubmit" class="btn-tbf blue center" @click="submitEnhancement">
							<div class="loader"></div>
							<div class="text">{{ $t('general.submit') }}</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import IconFile from "../Icons/File"
	import { required } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				body: '',
				attachments: '',
				filesName: []
			}
		},
		props:{
			data: Object
		},
		components: {
			IconClose,
			IconFile
		},
		validations: {
			body: {required}
		},
		mounted(){
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			submitEnhancement(){
				var buttonName = `buttonSubmit`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.$v.$touch()
				if(!this.$v.$invalid){

					var objData = new FormData();
					objData.append('body', this.body)
					objData.append('procedure_id', this.data.type == 'procedures' ? this.data.id : '')
					objData.append('process_id', this.data.type == 'process' ? this.data.id : '')

					for( var i = 0; i < this.attachments.length; i++ ){
						let file = this.attachments[i];
						objData.append('files[' + i + ']', file);
					}

					axios.post('/improvement-proposals/store', objData)
					.then(({data}) => {
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('completed')
								btnSubmitText.innerHTML = this.$t('general.submit')
								if(this.data.type == 'procedures'){
									this.$root.$emit('refreshProcedureShow')
								} else if(this.data.type == 'process'){
									this.$root.$emit('refreshImprovementsProcesses')
								}
								this.$emit("close_modal");
							}, 1000)
						}, 300)
					})
					.catch(error => {
						// console.log(error)
					})
					.finally(() => {
						setTimeout(()=>{
							btnSubmit.disabled = false
						}, 1500)
					})
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.submit')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			onFilesChange(e){
				this.filesName = []

				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.attachments = files

				files.forEach(el => {
					this.filesName.push(el.name)
				})
			}
		}
	}
</script>

<style lang="scss">
	.label-file-btn{
		height: 38px;
		padding: 0 45px;
		display: flex;
		align-items: center;
		.upload-text{
			display: flex;
			font-size: 12px;
			color: $primary;
			cursor: pointer;
			&:hover{
				text-decoration: underline;
			}
		}
		.selected-files{
			font-size: 12px;
			color: $grey;
			margin-left: 10px;
		}
	}
</style>