<template>
	<div class="sidebar-settings-tbf">
		<div class="header">
			{{ $t('settings-navbar.title') }}
		</div>
		<div class="main-menu" v-if="soft_organigram">
			<div v-if="$auth.user().rights_name.includes('master_admin') && soft_organigram.is_active" class="item-menu">
				<a :href="`${organigram_fe_link}/settings/work-schedules`">
					<span class="text">{{$t('settings-navbar.work-schedules')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</a>
			</div>
			<div v-if="$auth.user().rights_name.includes('master_admin') && soft_organigram.is_active" class="item-menu">
				<a :href="`${organigram_fe_link}/settings/departments`">
					<span class="text">{{$t('settings-navbar.departments')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</a>
			</div>
			<div v-if="$auth.user().rights_name.includes('master_admin') && soft_organigram.is_active" class="item-menu">
				<a :href="`${organigram_fe_link}/settings/workstations`">
					<span class="text">{{$t('settings-navbar.workstations')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</a>
			</div>
			<div v-if="$auth.user().rights_name.includes('master_admin') && soft_organigram.is_active" class="item-menu">
				<a :href="`${organigram_fe_link}/settings/skills`">
					<span class="text">{{$t('settings-navbar.skills')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</a>
			</div>
			<div v-if="$auth.user().rights_name.includes('master_admin') && soft_organigram.is_active" class="item-menu">
				<a :href="`${organigram_fe_link}/settings/holidays`">
					<span class="text">{{$t('settings-navbar.holidays')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</a>
			</div>
			<div v-if="$auth.user().rights_name.includes('master_admin') && soft_organigram.is_active" class="item-menu">
				<a :href="`${organigram_fe_link}/settings/company`">
					<span class="text">{{$t('settings-navbar.company')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</a>
			</div>
			<div v-if="$auth.user().rights_name.includes('master_admin') || $auth.user().rights_name.includes('admin')" class="item-menu">
				<router-link :to="{name: 'settings-categories' }">
					<span class="text">{{ $t('settings-navbar.categories') }}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div v-if="$auth.user().rights_name.includes('master_admin') && soft_organigram.is_active" class="item-menu">
				<a :href="`${organigram_fe_link}/settings/profile`">
					<span class="text">{{$t('settings-navbar.profile')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</a>
			</div>
			<div class="item-menu" v-if="$auth.user().is_entrepreneur && !$auth.user().free_account">
				<router-link :to="{name: 'settings-subscription'}">
					<span class="text">{{ $t('settings-navbar.subscriptions') }}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
    import IconArrow from '../Icons/Arrow.vue'

	export default {
		components: {
            IconArrow
		},
		data() {
			return {
				objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK,
				organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK
			};
		},
		computed: {
			soft_objectives() {
				return this.$store.getters['applications/getApplication']('Obiective');
			},
			soft_organigram() {
				return this.$store.getters['applications/getApplication']('Organigrama');
			}
		},
		mounted() {
			
		},
		methods: {

		}
	};
</script>

<style lang="scss">
    @import "../../styles/templates/settings-template.scss";
</style>