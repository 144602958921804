<template>
	<div class="container-content-modal">
		<div class="form-section">
			<div class="input-group w-100">

				<div class="label-header">
					<label class="label">{{ $t('general.choose-color') }}*</label>
				</div>
				<div class="radio-colors">
					<div v-for="color in colors" class="box-colors">
						<div class="tag-color" v-bind:class="[ color ? `${color}` : '', {selected: selectedColor == color}]" @click="selectColor(color)">
							<span></span>
						</div>
					</div>
				</div>

				<div class="label-header">
					<label class="label">{{ $t('general.decision') }}*</label>
					<div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
					<div class="icon-left"><icon-decision /></div>
					<div class="icon-right" v-if="name" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
					<input type="text" :placeholder="$t('process-chart.modal.placeholder_decision')" class="input-text" v-model="name">
				</div>
			</div>

			<div class="input-group inline inline-decision w-100" v-for="(n, i) in count_conditions">
				<div class="label-header condition">
					<label class="label">{{ $t('general.condition') }} {{i == 0 ? n+'*' : n }}</label>
				</div>
				<div class="input-box" v-bind:class="{has_error: conditionsErrors[i]}">
					<div class="icon-left"><icon-list /></div>
					<div class="icon-right" v-if="conditions[i].value != ''" @click.stop="conditions[i].value = ''"><icon-close class="icon-clear" /></div>
					<input type="text" :id="`condition_${i}`" :placeholder="$t('process-chart.modal.condition_placeholder')" class="input-text" v-model="conditions[i].value" @keyup.enter="addOneMoreCondition">
				</div>
				<div class="delete-decision">
					<button class="btn-tbf white only-icon" v-bind:class="{disabled: count_conditions == 1}" @click="deleteCondition(i)" :disabled="count_conditions == 1">
						<icon-trash />
					</button>
				</div>
			</div>
			<div class="add-one-more">
				<button class="btn-tbf-link blue" @click="addOneMoreCondition">
					<div class="text">{{ $t('process-chart.modal.add_one_more_condition') }}</div>
				</button>
			</div>
		</div>
		<div class="form-submit">
			<button class="btn-tbf grey center" @click="changeSlide('main_form')">
				<div class="loader"></div>
				<div class="text">{{$t('general.cancel')}}</div>
			</button>
			<button id="buttonNextStep" class="btn-tbf blue center" @click="saveStep">
				<div class="loader-spin white" v-show="loadingSave"></div>
				<div class="text" v-show="!loadingSave">{{ actionEdit ? $t('general.update') : $t('process-chart.modal.add-decision')}}</div>
			</button>
		</div>

		<div class="submodal-container" v-if="subModalShow">
			<div class="overlay-submodal"></div>
			<!-- <confirm-close v-if="show_subm_modal.confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" /> -->
			<modal-confirm-delete id="submodal_confirm_delete" v-if="show_subm_modal.confirm_delete" :data="{type: 'condition_edit', from: 'confirm_delete'}" @close_modal="cancelDelete" @confirm_delete="confirmDelete" />
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../../Icons/Close"
	import IconList from "../../Icons/List"
	import IconTrash from "../../Icons/Trash"
	import IconDecision from "../../Icons/Decision"
	import ModalConfirmDelete from "../Delete"
	import { required } from 'vuelidate/lib/validators'
	import IconCheck from "../../Icons/Check"

	export default {
		data() {
			return {
				loadingSave: false,
				actionEdit: false,
				name: '',
				conditionsErrors: [],
				conditions: [
					{value: ''}
				],
				count_conditions: 1,
				parseData: {},
				subModalShow: false,
                show_subm_modal: {
                	confirm_close: false,
                	confirm_delete: false
                },
                awaitDeleteIndex: '',
                selectedColor: '',
				colors: [
					"grey",
					"purple",
					"blue",
					"green",
					"red",
					"yellow",
				]
			}
		},
		props: {
			data: String
		},
		components: {
			IconClose,
			IconList,
			IconTrash,
			IconDecision,
			ModalConfirmDelete,
			IconCheck
		},
		validations: {
			name: {required}
		},
		mounted(){
			if(this.data != ''){
				this.actionEdit = true
				this.populateInputs()
			}
		},
		methods: {
			changeSlide(slide){
				this.$emit('change_slide', slide)
			},
			addOneMoreCondition(){
				this.conditions.push({value: ''})
				this.count_conditions++

				setTimeout(() => {
					document.getElementById("condition_" + (this.count_conditions - 1)).focus()
				}, 100)
			},
			selectColor(colorId){
				this.selectedColor = colorId
			},
			deleteCondition(index){
				if(this.conditions[index].hasOwnProperty('unique_id')){
					this.awaitDeleteIndex = index
					// confirm MODAL
					this.subModalShow = true
					this.show_subm_modal.confirm_delete = true
					setTimeout(() => {
						$('.submodal-container').addClass('active');
						setTimeout(() => {
							$('#submodal_confirm_delete').addClass('active');
						}, 200);
	                }, 0);

				}else {
					this.conditions.splice(index, 1)
					this.count_conditions--
				}
			},
			saveStep(){
				var buttonName = 'buttonNextStep';
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitText = document.querySelector(`#${buttonName} .text`);
				btnSubmit.disabled = true
				this.loadingSave = true

				this.$v.$touch()
				if(this.checkConditions() && !this.$v.$invalid){
					this.savedConditions = this.conditions.filter(el => el.value != "")
					if(this.actionEdit){
						var objSave = {...this.parseData}
						objSave.name = this.name;
						objSave.conditions = this.savedConditions
						objSave.color = this.selectedColor
						this.$emit('store_step', objSave)
					}else{
						this.$emit('store_step', {type: 'decision', name: this.name, conditions: this.savedConditions,  color: this.selectedColor})
					}
				}else{
					this.loadingSave = false
					btnSubmitText.innerHTML = this.$t('btn-submit.error')
					btnSubmit.classList.add('error')
					setTimeout(()=>{
						btnSubmit.classList.remove('error')
						btnSubmitText.innerHTML = this.$t('process-chart.modal.add-decision')
						btnSubmit.disabled = false
					}, 1000)
				}
			},
			checkConditions(){
				this.conditionsErrors = []
				this.conditions.forEach((el, i) => {
					if(el.value == '' && i < 1){
						this.conditionsErrors[i] = true
					}
				})
				if(this.conditionsErrors.length) return false
				else return true
			},
			populateInputs(){
				this.parseData = JSON.parse(this.data)

				this.name = this.parseData.name

				this.conditions = []
				this.count_conditions = 0

				this.parseData.children.map(el => {
					this.count_conditions++
					var objToPush = {...el}
					objToPush.value = el.condition
					this.conditions.push(objToPush)
				})
			},
			cancelDelete(){
				$("#submodal_confirm_delete").removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal.confirm_delete = false
					}, 200);
				}, 200);
			},
			confirmDelete(){
				$("#submodal_confirm_delete").removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal.confirm_delete = false
						
						this.conditions.splice(this.awaitDeleteIndex, 1)
						this.count_conditions--
					
					}, 200);
				}, 200);

			}
		}
	}
</script>

<style lang="scss">
	.form-section{
		.add-one-more{
			padding: 0 15px;
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
		.input-group{
			&.inline-decision{
				@media(max-width: 600px){
					flex-wrap: wrap;
				}
			}
		}
	}
</style>

<style lang="scss">
	.label-header.condition{
		.label{
			font-size: 13px !important
		}
	}
</style>