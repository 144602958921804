<template>
	<div class="main-template">
        <template v-if="loadedApplications">
            <sidebar v-if="$resize && $mq.above(781)"/>
            <sidebar-settings v-if="$resize && $mq.above(781)" />
            <div class="container-tbf">
                <navbar />
                <div class="container-content">
                    <slot></slot>
                </div>
                <sidebar-mobile v-if="$resize && $mq.below(780)"/>
            </div>
            <notifications/>

            <div class="overflow-modal" v-if="show_overflow">
                <div class="bg-modals" @click="closeModals()"></div>

                <modal-delete id="modal_delete" v-if="show_modal.delete" :data="data.delete" @close_modal="closeModal('delete')" />
                <modal-change-subscription id="modal_change_subscription" v-if="show_modal.change_subscription" @close_modal="closeModal('change_subscription')"/>
                <modal-cancel-subscription id="modal_cancel_subscription" v-if="show_modal.cancel_subscription" @close_modal="closeModal('cancel_subscription')" />
                <modal-renew-subscription id="modal_renew_subscription" v-if="show_modal.renew_subscription" @close_modal="closeModal('renew_subscription')" />
                <modal-change-plan-confirmation id="modal_change_plan_confirmation" v-if="show_modal.change_plan_confirmation" :data="data.change_plan_confirmation" @close_modal="closeModal('change_plan_confirmation')"/>
                <modal-notifications id="modal_notifications" v-if="show_modal.notifications" @close_modal="closeModal('notifications')"/>
                <modal-settings-mobile id="modal_settings_mobile" v-if="show_modal.settings_mobile" @close_modal="closeModal('settings_mobile')"/>
                <modal-contact id="modal_contact" v-if="show_modal.contact" @close_modal="closeModal('contact')"/>
                <modal-webinar-tehnic id="modal_webinar_tbf" v-if="show_modal.webinar_tbf" @close_modal="closeModal('webinar_tbf')"/>
                <modal-mission-vision id="modal_mission_vision" v-if="show_modal.mission_vision" @close_modal="closeModal('mission_vision')" />
                <modal-grace-period id="modal_payment_error" v-if="show_modal.payment_error" @close_modal="closeModal('payment_error')"/>
            </div>
        </template>
        <div class="page-loader-on-start" v-else>
            <div class="loader-spin"></div>
        </div>
	</div>
</template>

<script>
    import Sidebar from '../General/Sidebar.vue'
    import SidebarSettings from '../General/SidebarSettings.vue'
    import Navbar from '../General/Navbar.vue'
    import ModalChangePlanConfirmation from "../Modals/ChangePlanConfirmation"
    import ModalDelete from "../Modals/Delete"
    import ModalCancelSubscription from "../Modals/CancelSubscription"
    import ModalRenewSubscription from "../Modals/RenewSubscription"
    import ModalNotifications from "../General/SidebarNotifications"
    import ModalChangeSubscription from "../Modals/ChangeSubscription"
    import SidebarMobile from "../General/SidebarMobile"
    import ModalSettingsMobile from "../General/SidebarSettingsMobile"
    import ModalContact from "../Modals/Contact"
    import ModalWebinarTehnic from "../Modals/WebinarTehnic"
    import ModalMissionVision from "../Modals/MissionVision"
    import ModalGracePeriod from "../Modals/GracePeriod"
    import Notifications from "../General/Notifications"

    export default {
        data() {
            return {
                loadedApplications: false,
                show_overflow: false,
                show_modal: {
                    change_plan_confirmation: false,
                    cancel_subscription: false,
                    delete: false,
                    renew_subscription: false,
                    notifications: false,
                    contact: false,
                    webinar_tbf: false,
                    mission_vision: false,
                    payment_error: false
                },
                data: {
                    change_plan_confirmation: {},
                    delete: {}
                },
            }
        },
        components: {
            Sidebar,
            SidebarSettings,
            Navbar,
            ModalChangePlanConfirmation,
            ModalDelete,
            ModalCancelSubscription,
            ModalNotifications,
            ModalRenewSubscription,
            ModalChangeSubscription,
            SidebarMobile,
            ModalSettingsMobile,
            ModalContact,
            ModalWebinarTehnic,
            ModalMissionVision,
            ModalGracePeriod,
            Notifications
        },
        mounted(){
            this.$root.$on("open_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })

            if(this.$store.state.applications.applications.length){
                this.loadedApplications = true;
            } else {
                var refreshIntervalApps = setInterval(() => {
                    if(this.$auth.check()){
                        if(this.$store.state.applications.applications.length){
                            clearInterval(refreshIntervalApps);
                            this.loadedApplications = true;
                        }
                    }
                }, 50);
            }
        },
        methods: {
            closeModals(){
                this.closeModal()
            },
            closeModal(type = false, closeOverflow = true){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false

                    if(closeOverflow){
                        $('.overflow-modal').removeClass('show')
                        setTimeout(() => {
                            this.show_overflow = false;
                        }, 200)
                    }
                }, 200);
            }
        }
    }
</script>