<template>
	<div class="template-public">
  		<router-view/>
    </div>
</template>

<script type="text/javascript">

	export default {
		data() {
			return {

			};
		},
		mounted() {
			// this.checkLanguage();
		},
		methods: {
			closeModal(type = false){
				$("#modal_" + type).removeClass("active")
				setTimeout(() => {
					this.show_modal[type] = false
				}, 200);
			},
			checkLanguage(){
				if(this.$auth.check()){
					this.$i18n.locale = this.$auth.user().language
					this.$cookies.set('language', this.$auth.user().language, '1m')
				}else if(this.$cookies.get('language')){
					this.$i18n.locale = this.$cookies.get('language')
				}
			}
		}
	};
</script>

<style lang="scss">
    @import "../../styles/templates/login-template.scss";
</style>