<template>
    <div class="sidebar-settings">
    
        <div class="top">
            <h1>{{ $t('procedures.version_history') }}</h1>
            <div class="btn-tbf white only-icon close-btn" @click="closeModal">
                <icon-close />
            </div>
        </div>
        <div class="space-right history-list">
            <div class="list">
                <div class="item" v-for="version in data" v-bind:class="{active: version.id == item.history_version.id}" @click="viewVersion(version)">
                    <div class="date">{{ version.created_at | moment('DD MMMM YYYY, HH:mm') }}</div>
                    <div class="user">{{ version.username }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import IconClose from "../Icons/Close"
export default {
    data() {
        return {};
    },
    props: {
        data: Array,
        item: Object
    },
    components: {
        IconClose
    },
    async mounted() {
        // console.log('data item', this.item)
    },
    methods: {
        closeModal() {
            this.$emit("close_modal");
        },
        viewVersion(version) {
            this.$root.$emit('ViewVersionsHistory', version);
        }
    }
};
</script>

<style lang="scss">
.history-list {
    height: 100vh;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 300px;
    flex: 0 0 300px;
    overflow-y: auto; // border-left: 1px solid $borderColor;
    .title {
        height: 100px;
        min-height: 100px;
        display: flex;
        align-items: center;
        padding: 0 30px;
        color: $primary;
        font-size: 18px;
        font-weight: 500;
        border-bottom: 1px solid $borderColor;
        width: 100%;
    }
    .list {
        width: 100%;
        .item {
            width: 100%;
            padding: 20px 37px;
            border-bottom: 1px solid $borderColor;
            cursor: pointer;
            &:hover {
                background: #FCFCFC;
            }
            &.active {
                background: #f9f9f9;
            }
            .date {
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 5px;
                text-transform: capitalize;
            }
            .user {
                color: $grey;
                font-size: 14px;
            }
        }
    }
}
</style>