<template>
	<div class="container-content-modal">
		<div class="form-section">
			<div class="input-group w-100">

				<div class="label-header">
					<label class="label">{{ $t('general.choose-color') }}*</label>
				</div>
				<div class="radio-colors">
					<div v-for="color in colors" class="box-colors">
						<div class="tag-color" v-bind:class="[ color ? `${color}` : '', {selected: selectedColor == color}]" @click="selectColor(color)">
							<span></span>
						</div>
					</div>
				</div>

				<div class="label-header">
					<label class="label">{{ $t('process-chart.simple_text') }}</label>
					<div v-if="$v.blocText.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.blocText.$error}">
					<textarea v-model="blocText"></textarea>
				</div>
			</div>
		</div>
		<div class="form-submit">
			<button class="btn-tbf grey center" @click="changeSlide('main_form')">
				<div class="loader"></div>
				<div class="text">{{$t('general.cancel')}}</div>
			</button>
			<button id="buttonNextStep" class="btn-tbf blue center" @click="saveStep">
				<div class="loader-spin white" v-show="loadingSave"></div>
				<div class="text" v-show="!loadingSave">{{ actionEdit ? $t('general.update') : $t('process-chart.modal.add-simple-text') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import { required } from 'vuelidate/lib/validators'
	import IconCheck from "../../Icons/Check"

	export default {
		data() {
			return {
				loadingSave: false,
				blocText: '',
				actionEdit: false,
				parseData: {},
                selectedColor: '',
				colors: [
					"grey",
					"purple",
					"blue",
					"green",
					"red",
					"yellow",
				]
			}
		},
		props:{
			data: String
		},
		components: {
			IconCheck
		},
		validations: {
			blocText: {required}
		},
		mounted(){
			if(this.data != ''){
				this.actionEdit = true
				this.parseData = JSON.parse(this.data)
				this.selectedColor = this.parseData.color;
				this.blocText = this.parseData.name;
			}
		},
		methods: {
			changeSlide(slide){
				this.$emit('change_slide', slide)
			},
			selectColor(colorId){
				this.selectedColor = colorId
			},
			saveStep(){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true
				this.loadingSave = true

				this.$v.$touch()
				if(!this.$v.$invalid){
					if(this.actionEdit){
						var objSave = {...this.parseData}
						objSave.name = this.blocText;
						objSave.color = this.selectedColor;
						this.$emit('store_step', objSave)
					}else{
						this.$emit('store_step', {type: 'simple_text', name: this.blocText, color: this.selectedColor})
					}
				}else{
					this.loadingSave = false
					btnSubmitText.innerHTML = this.$t('btn-submit.error')
					btnSubmit.classList.add('error')
					setTimeout(()=>{
						btnSubmit.classList.remove('error')
						btnSubmitText.innerHTML = this.$t('process-chart.modal.add-simple-text')
						btnSubmit.disabled = false
					}, 1000)
				}
			}
		}
	}
</script>