<template>
	<div class="modal-tbf modal-add-step">
		<div class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">
					{{ intermediateStep ? $t('process-chart.modal.add-intermediate-step') : $t('process-chart.modal.add-step') }}
				</div>
				<div class="actions" @click="closeModal">
					<div class="btn-tbf white only-icon close-btn"><icon-close class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<transition name="slide" mode="out-in">
					<div class="container-content-modal" v-if="activeSlide == 'main_form'">
						<div class="list-type-step">
							<div class="type" @click="activeSlide = 'process'">
								<div class="box" :title="$t('process-chart.process')">
									<icon-chart />
								</div>
								<div class="text">{{ $t('process-chart.process') }}</div>
							</div>
							<div class="type" @click="activeSlide = 'procedure'">
								<div class="box" :title="$t('process-chart.procedure')">
									<icon-procedures />
								</div>
								<div class="text">{{ $t('process-chart.procedure') }}</div>
							</div>
							<div class="type" @click="activeSlide = 'text'">
								<div class="box" :title="$t('process-chart.simple_text')">
									<icon-text />
								</div>
								<div class="text">{{ $t('process-chart.simple_text') }}</div>
							</div>
							<div class="type" v-if="!intermediateStep" @click="activeSlide = 'decision'">
								<div class="box" :title="$t('process-chart.decision')">
									<icon-decision />
								</div>
								<div class="text">{{ $t('process-chart.decision') }}</div>
							</div>
							<div class="type" v-if="!intermediateStep" @click="addLoopStep">
								<div class="box" :title="$t('process-chart.loop_text_step')">
									<icon-loop />
								</div>
								<div class="text">{{ $t('process-chart.loop_text_step') }}</div>
							</div>
							<div class="type" v-if="!intermediateStep" @click="addStepFinish">
								<div class="box" :title="$t('process-chart.end')">
									END
								</div>
								<div class="text">{{ $t('process-chart.end') }}</div>
							</div>
						</div>
					</div>
					<add-step-process v-if="activeSlide == 'process'" @change_slide="changeSlide" @store_step="addNewStep"/>
					<add-step-procedure v-if="activeSlide == 'procedure'" @change_slide="changeSlide" @store_step="addNewStep"/>
					<add-step-decision v-if="activeSlide == 'decision'" data="" @change_slide="changeSlide" @store_step="addNewStep"/>
					<add-step-simple-text v-if="activeSlide == 'text'" data="" @change_slide="changeSlide" @store_step="addNewStep"/>
					<add-step-loop v-if="activeSlide == 'loop'" :data="data" @close_modal="closeModal" @store_step="addNewStep"/>
				</transition>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../../Icons/Close"
	import AddStepProcess from "./AddStepProcess"
	import AddStepProcedure from "./AddStepProcedure"
	import AddStepDecision from "./AddStepDecision"
	import AddStepSimpleText from "./AddStepSimpleText"
	import AddStepLoop from "./AddStepLoop"
	import IconLoop from '../../Icons/Loop'
	import IconText from '../../Icons/Text'
	import IconProcedures from '../../Icons/ProceduresLight'
	import IconChart from '../../Icons/Chart'
	import IconDecision from '../../Icons/Decision'

	export default {
		data() {
			return {
				loaded: false,
				intermediateStep: false,
				activeSlide: 'main_form',
				countUniqueNr: 1,
				processesIds: [],
				proceduresIds: [],
				parse_data: {},
				parse_json: {}
			}
		},
		props:{
			data: String,
			json: String,
			processData: Object,
			modalType: String
		},
		components: {
			IconClose,
			AddStepProcess,
			AddStepProcedure,
			AddStepDecision,
			AddStepSimpleText,
			AddStepLoop,
			IconLoop,
			IconText,
			IconProcedures,
			IconChart,
			IconDecision
		},
		mounted(){
			this.parse_data = JSON.parse(this.data)
			this.parse_json = JSON.parse(this.json)

			if(this.parse_data.children && this.parse_data.children.length){
				this.intermediateStep = true
			}

			if(this.modalType) {
				this.activeSlide = this.modalType
			}

			this.loopJson(this.parse_json)

			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			changeSlide(slide){
				this.activeSlide = slide
			},
			loopJson(jsonChildren){
				if(jsonChildren.children){
					jsonChildren.children.map(el => {
						var number = el.unique_id
						this.countUniqueNr = this.countUniqueNr >= number ? this.countUniqueNr : number++
						this.loopJson(el)
					})
				}
			},
			addNewStep(childrenData){
				if(this.parse_data.type != 'blank'){
					this.countUniqueNr++
				}

				var newChildren = {
					unique_id: this.parse_data.type == 'blank' ? this.parse_data.unique_id : this.countUniqueNr,
					type: childrenData.type,
					color: childrenData.color
				}

				if(childrenData.type == 'procedure'){
					newChildren.id = childrenData.value.id
				}

				if(childrenData.type == 'process'){
					newChildren.id = childrenData.value.id
				}

				if(childrenData.type == 'decision'){
					var decisions = []
					childrenData.conditions.map(el => {
						this.countUniqueNr++
						decisions.push({unique_id: this.countUniqueNr, condition: el.value, type: 'blank'})
					})

					newChildren.name = childrenData.name
					newChildren.children = decisions
				}

				if(childrenData.type == 'simple_text'){
					newChildren.name = childrenData.name
				}

				if(childrenData.type == 'loop'){
					newChildren.name = childrenData.name
					newChildren.selectedParentLoop = childrenData.selectedParentLoop
				}

				if(this.parse_data.type == 'blank'){
					newChildren.condition = this.parse_data.condition
					newChildren.extend = this.parse_data.extend

					this.parse_data = newChildren
				}else{
					if(this.parse_data.children){
						newChildren.children = [...this.parse_data.children]
						this.parse_data.children = [newChildren]
					}else{
						this.parse_data.children = [newChildren]
					}
				}

				this.storeJson()
			},
			addStepFinish(){
				if(this.parse_data.type != 'blank'){
					this.countUniqueNr++
				}
				
				var newChildren = {
					unique_id: this.parse_data.type == 'blank' ? this.parse_data.unique_id : this.countUniqueNr,
					type: 'end',
					name: "End"
				}

				if(this.parse_data.type == 'blank'){
					newChildren.condition = this.parse_data.condition
					newChildren.extend = this.parse_data.extend
					this.parse_data = newChildren
				}else{
					if(this.parse_data.children){
						this.parse_data.children.push(newChildren)
					}else{
						this.parse_data.children = [newChildren]
					}
				}

				this.storeJson()
			},
			addLoopStep() {
				this.$root.$emit('enter_mode_select_loop', this.parse_data);
				this.$emit("close_modal");
			},
			getObjects(obj, val, newVal) {
				var newValue = newVal;
				var objects = [];
				for (var i in obj) {
					if (!obj.hasOwnProperty(i)) continue;

					if (typeof obj[i] == 'object') {
						objects = objects.concat(this.getObjects(obj[i], val, newValue));
					} else if (i == 'unique_id' && obj['unique_id'] == val) {
						var blank = obj['type'] == 'blank' ? true : false

						if(newValue.children){ obj['children'] = newValue.children }
						if(newValue.unique_id){ obj['unique_id'] = newValue.unique_id }
						if(newValue.id){ obj['id'] = newValue.id }
						if(newValue.type){ obj['type'] = newValue.type }
						if(newValue.extend){ obj['extend'] = newValue.extend }
						if(newValue.color){ obj['color'] = newValue.color }
						if(newValue.selectedParentLoop){ obj['selectedParentLoop'] = newValue.selectedParentLoop }

						if(newValue.name){ obj['name'] = newValue.name }

						if(blank){
							if(obj['type'] == 'process') this.processesIds.push(obj['id'])
							if(obj['type'] == 'procedure') this.proceduresIds.push(obj['id'])
						}

						if(newValue.children){
							newValue.children.map(el => {
								if(el.type == 'process') this.processesIds.push(el.id)
								if(el.type == 'procedure') this.proceduresIds.push(el.id)
							})
						}
					}

					if ( 
						i == 'unique_id' && 
						( 
							( newValue.hasOwnProperty('selectedParentLoop') && obj['unique_id'] == newValue.selectedParentLoop.unique_id ) || 
							( newValue.hasOwnProperty('temporarySelectedParentLoop') && obj['unique_id'] == newValue.temporarySelectedParentLoop.unique_id)
						)
					 ) {
						if(!obj.hasOwnProperty('is_parent')){
							if(newValue.hasOwnProperty('temporarySelectedParentLoop')) {
								obj['is_parent'] = [newValue.children[0].unique_id]
							} else {
								obj['is_parent'] = [val]
							}
						} else {

							if(newValue.hasOwnProperty('temporarySelectedParentLoop')) {
								obj['is_parent'].push(newValue.children[0].unique_id)
							} else {
								obj['is_parent'].push(val)
							}
						}
					}

					if (i == 'type') {
						if(obj['type'] == 'process') this.processesIds.push(obj['id'])
						else if (obj['type'] == 'procedure') this.proceduresIds.push(obj['id'])
					}

				}

				return obj;
			},
			storeJson(){
				this.processesIds = []
				this.proceduresIds = []

				var newJson = this.getObjects(this.parse_json, this.parse_data.unique_id, this.parse_data)

				axios.post(`/procedures/${this.processData.slug}/sync`, { 
					version_id: this.processData.version_id, 
					procedures: this.proceduresIds, 
					processes: this.processesIds, 
					json: JSON.stringify(newJson)
				})
				.then(({data}) => {
					this.$root.$emit('refreshProcessTree', {json: data.data.json, processes_info: data.data.processes_info, procedures_info: data.data.procedures_info})
				})
				.finally(() => {
					this.$emit("close_modal")
				})
			}
		}
	}
</script>