import Vue    from 'vue';

import http   from './http'
import store  from './store'
import router from './router'
import config from './config'
import i18n from './i18n'

import JQuery from 'jquery'
window.$ = JQuery

import App from './components/App.vue';

// import './libraries/htmldiff.js'
window.diff = require("node-htmldiff");

import 'bootstrap'
import "./styles/global.scss"

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueCardFormat from 'vue-credit-card-validation'
Vue.use(VueCardFormat)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
Vue.component('vue-timepicker', VueTimepicker)

import VueMoment from 'vue-moment'
import momentTz from 'moment-timezone'
window.moment = require('moment');
moment.updateLocale('ro', { week: { dow: 1, } })

window.moment.locale('ro', {
  relativeTime: {
    future: '+ %s',
  }
});

Vue.use(VueMoment, {moment, momentTz});
Vue.prototype.moment = moment;

import FunctionalCalendar from 'vue-functional-calendar'
Vue.use(FunctionalCalendar, {
	dayNames: moment.weekdaysMin(true),
	monthNames: moment.months(),
	shortMonthNames: moment.monthsShort()
});

import {MediaQueries} from 'vue-media-queries';
const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);

import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    http: http,
    store: store,
    router: router,
    config: config,
    i18n,
    mediaQueries: mediaQueries,
    render: h => h(App)
});