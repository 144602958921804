<template>
	<div class="container-content-modal">
		<div class="form-section">
			<div class="input-group w-100">
				<div class="label-header">
					<label class="label">{{ $t('general.condition') }}*</label>
					<div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
					<div class="icon-left"><icon-decision /></div>
					<div class="icon-right" v-if="name" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
					<input type="text" :placeholder="$t('process-chart.modal.placeholder_decision')" class="input-text" v-model="name">
				</div>
			</div>
		</div>
		<div class="form-submit">
			<button class="btn-tbf grey center" @click="$emit('close_modal')">
				<div class="loader"></div>
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonNextStep" class="btn-tbf blue center" @click="saveStep">
				<div class="loader"></div>
				<div class="text">{{ $t('general.update') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import IconDecision from "../Icons/Decision"
	import { required } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				name: '',
				parseData: {}
			}
		},
		props: {
			data: String
		},
		components: {
			IconClose,
			IconDecision,
		},
		validations: {
			name: {required}
		},
		mounted(){
			this.parseData = JSON.parse(this.data)
			this.name = this.parseData.condition
		},
		methods: {
			saveStep(){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				this.$v.$touch()

				if(!this.$v.$invalid){
					var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
					var btnSubmitText = document.querySelector(`#${buttonName} .text`)

					btnSubmit.classList.add('loading')
					btnSubmitLoader.classList.add('onProgress')
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')

					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							setTimeout(()=>{
								var objSave = {...this.parseData}
								objSave.condition = this.name
								this.$emit('store_step', objSave)
							}, 200)
						}, 1000)
					}, 300)
				}else{
					btnSubmit.disabled = false
				}
			}
		}
	}
</script>