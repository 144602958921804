<template>
	<div class="modal-tbf modal-add-step">
		<div class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">
					{{ $t('process-chart.modal.edit-step') }}
				</div>
				<div class="actions" @click="closeModal">
					<div class="btn-tbf white only-icon close-btn"><icon-close class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<add-step-simple-text v-if="activeSlide == 'selected_simple_text'" :data="data" @change_slide="closeModal" @store_step="saveStep"/>
				<add-step-decision v-if="activeSlide == 'selected_decision'" :data="data" @change_slide="closeModal" @store_step="saveStep"/>
				<edit-step-condition v-if="activeSlide == 'selected_condition'" :data="data" @change_slide="closeModal" @store_step="saveStep"/>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import AddStepProcess from "./AddStep/AddStepProcess"
	import AddStepProcedure from "./AddStep/AddStepProcedure"
	import AddStepDecision from "./AddStep/AddStepDecision"
	import AddStepSimpleText from "./AddStep/AddStepSimpleText"
	import EditStepCondition from "./EditStepCondition"

	export default {
		data() {
			return {
				loaded: false,
				activeSlide: 'main_form',
				countUniqueNr: 1,
				processesIds: [],
				proceduresIds: [],
				parse_data: {},
				parse_json: {}
			}
		},
		props:{
			data: String,
			json: String,
			processData: Object
		},
		components: {
			IconClose,
			AddStepProcess,
			AddStepProcedure,
			AddStepDecision,
			EditStepCondition,
			AddStepSimpleText
		},
		mounted(){
			this.parse_json = JSON.parse(this.json)

			if(JSON.parse(this.data).type == 'decision'){
				this.activeSlide = 'selected_decision'
			}

			if(JSON.parse(this.data).type == 'simple_text'){
				this.activeSlide = 'selected_simple_text'
			}

			if(JSON.parse(this.data).isCondition){
				this.activeSlide = 'selected_condition'
			}

			this.loopJson(this.parse_json)
			
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			loopJson(jsonChildren){
				if(jsonChildren.children){
					jsonChildren.children.map(el => {
						var number = el.unique_id
						this.countUniqueNr = this.countUniqueNr >= number ? this.countUniqueNr : number++
						this.loopJson(el)
					})
				}
			},
			saveStep(childrenData){
				if(childrenData.type == 'decision' && !JSON.parse(this.data).isCondition){
					var decisions = []
					childrenData.conditions.map(el => {
						if(!el.hasOwnProperty('unique_id')){
							this.countUniqueNr++
							decisions.push({unique_id: this.countUniqueNr, condition: el.value, type: 'blank'})
						}else{
							var objToPush = {...el}
							objToPush.condition = el.value
							delete objToPush.value

							decisions.push(objToPush)
						}
					})
					childrenData.children = decisions
				}

				this.parse_data = childrenData
				this.storeJson()
			},
			getObjects(obj, val, newVal) {
				var newValue = newVal;
				var objects = [];
				for (var i in obj) {
					if (!obj.hasOwnProperty(i)) continue;

					if (typeof obj[i] == 'object') {
						objects = objects.concat(this.getObjects(obj[i], val, newValue));
					} else if (i == 'unique_id' && obj['unique_id'] == val) {

						if(newValue.hasOwnProperty('children')){ obj['children'] = newValue.children }
						if(newValue.hasOwnProperty('unique_id')){ obj['unique_id'] = newValue.unique_id }
						if(newValue.hasOwnProperty('id')){ obj['id'] = newValue.id }
						if(newValue.hasOwnProperty('type')){ obj['type'] = newValue.type }
						if(newValue.hasOwnProperty('extend')){ obj['extend'] = newValue.extend }
						if(newValue.hasOwnProperty('name')){ obj['name'] = newValue.name }
						if(newValue.hasOwnProperty('color')){ obj['color'] = newValue.color }
						if(newValue.hasOwnProperty('condition')){ obj['condition'] = newValue.condition }
						if(newValue.hasOwnProperty('selectedParentLoop')){ obj['selectedParentLoop'] = newValue.selectedParentLoop }

						if(newValue.children){
							newValue.children.map(el => {
								if(el.type == 'process') this.processesIds.push(el.id)
								if(el.type == 'procedure') this.proceduresIds.push(el.id)
							})
						}
					}

					if (i == 'type') {
						if(obj['type'] == 'process') this.processesIds.push(obj['id'])
						else if (obj['type'] == 'procedure') this.proceduresIds.push(obj['id'])
					}
				}

				return obj;
			},
			storeJson(){
				this.processesIds = []
				this.proceduresIds = []

				var newJson = this.getObjects(this.parse_json, this.parse_data.unique_id, this.parse_data)

				axios.post(`/procedures/${this.processData.slug}/sync`, { 
					version_id: this.processData.version_id, 
					procedures: this.proceduresIds, 
					processes: this.processesIds, 
					json: JSON.stringify(newJson)
				})
				.then(({data}) => {
					this.$root.$emit('refreshProcessTree', {json: data.data.json, processes_info: data.data.processes_info, procedures_info: data.data.procedures_info})
				})
				.finally(() => {
					this.$emit("close_modal")
				})
			}
		}
	}
</script>