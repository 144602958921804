<template>
	<div class="container-content-modal">
		<div class="payment-modal">
			<div class="package-selected">
				<div class="box active">
					<div class="header">
						<div class="title">{{selectedPackage.package_name}}</div>
						<div class="selected-check"><icon-check /></div>
					</div>
					<div class="price-box">
						<div class="value">{{ newPrices.find((el) => el.package_id == selectedPackage.package_id) ? newPrices.find((el) => el.package_id == selectedPackage.package_id).price + ' EUR' : selectedPackage.price_in_euro + ' EUR' }}</div>
						<div class="period">/ {{ $t('general.monthly') }}</div>
					</div>
					<div class="list-features">
						<div class="item-feature" v-for="textFe in selectedPackage.includes">
							<div class="text">{{ textFe }}</div>
							<div class="icon-square"><icon-check /></div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-section">
				<div class="input-group w-100">
					<div class="label-header">
						<label class="label">{{$t('subscription.card_number')}}*</label>
						<div class="error-msg" v-if="cardErrors.cardNumber">{{ cardErrors.cardNumber }}</div>
						<div class="error-msg" v-else-if="stripeError != ''">{{ stripeError }}</div>
					</div>
					<div class="input-box" v-bind:class="{has_error: $v.card.card_number.$error, complete: card.card_number != '' && !$v.card.card_number.$error}">
						<div class="icon-left"><icon-card /></div>
						<div class="icon-right" v-if="card.card_number != ''" @click.stop="card.card_number = ''"><icon-close class="icon-clear" /></div>
						<input type="text" placeholder="**** **** **** **** 2452" class="input-text" v-model="card.card_number" v-cardformat:formatCardNumber maxlength="19">
					</div>
				</div>
				<div class="input-group w-50">
					<div class="label-header">
						<label class="label">{{ $t('subscription.validity_date') }}*</label>
						<div class="error-msg" v-if="cardErrors.cardExpiry">{{ cardErrors.cardExpiry }}</div>
					</div>
					<div class="input-box" v-bind:class="{has_error: $v.card.card_expiration.$error, complete: card.card_expiration != '' && !$v.card.card_expiration.$error}">
						<div class="icon-left"><icon-date /></div>
						<div class="icon-right" v-if="card.card_expiration != ''" @click.stop="card.card_expiration = ''"><icon-close class="icon-clear" /></div>
						<input type="tel" placeholder="10 / 2030" class="input-text" v-model="card.card_expiration" v-cardformat:formatCardExpiry maxlength="9">
					</div>
				</div>
				<div class="input-group w-50">
					<div class="label-header">
						<label class="label">{{ $t('subscription.cvc') }}*</label>
						<div class="error-msg" v-if="cardErrors.cardCvc">{{ cardErrors.cardCvc }}</div>
					</div>
					<div class="input-box" v-bind:class="{has_error: $v.card.card_cvc.$error, complete: card.card_cvc != '' && !$v.card.card_cvc.$error}">
						<div class="icon-left"><icon-password /></div>
						<div class="icon-right" v-if="card.card_cvc != ''" @click.stop="card.card_cvc = ''"><icon-close class="icon-clear" /></div>
						<input type="tel" placeholder="***" class="input-text" v-model="card.card_cvc" v-cardformat:formatCardCVC maxlength="4">
					</div>
				</div>
				<div class="submit-form">
					<button id="buttonSubmitPayment" class="btn-tbf blue center" @click="submitPayment">
						<div class="loader"></div>
						<div class="text">{{$t('register.next_step')}}</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconClose from "../Icons/Close"
	import IconCheck from '../Icons/Check'
	import IconPassword from '../Icons/Password'
	import IconCard	from '../Icons/Card'
	import IconDate from '../Icons/Date'
	import { required } from 'vuelidate/lib/validators'

	export default {
		data(){
			return {
				card:{
					card_number: '',
					card_expiration: '',
					card_cvc: ''
				},
				cardErrors: {},
				stripeError: ''
			}
		},
		props:{
			user: Object,
			selectedPackage: Object,
			newPrices: Array
		},
		components: {
			IconClose,
			IconCheck,
			IconPassword,
			IconCard,
			IconDate
		},
		validations: {
			card: {
				card_number: {required},
				card_expiration: {required},
				card_cvc: {required}
			}
		},
		mounted() {
			
		},
		methods: {
			submitPayment(){
				var buttonName = 'buttonSubmitPayment'
                var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

                btnSubmit.disabled = true

				this.$v.$touch()

				this.cardErrors = {}
				this.stripeError = ''
				var cardError = false
				// validate card number
				if(!this.$cardFormat.validateCardNumber(this.card.card_number)){
					this.cardErrors.cardNumber = this.$t('validator.invalid_credit_card');
					cardError = true
				};

				// validate card expiry
				if (!this.$cardFormat.validateCardExpiry(this.card.card_expiration)) {
					this.cardErrors.cardExpiry = this.$t('validator.invalid_credit_card_date');
					cardError = true
				};

				// validate card CVC
				if (!this.$cardFormat.validateCardCVC(this.card.card_cvc)) {
					this.cardErrors.cardCvc = this.$t('validator.invalid_credit_card_cvc');
					cardError = true
				};

				if(this.$v.$invalid || cardError){
					btnSubmit.disabled = false
				}else{
					btnSubmit.classList.add('loading')
					btnSubmitLoader.classList.add('onProgress')
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')

					this.storePayment(btnSubmit, btnSubmitLoader, btnSubmitText)
				}
			},
			storePayment(btnSubmit, btnSubmitLoader, btnSubmitText){
				let paymetFormData = {
					number: this.card.card_number,
					exp_month: this.card.card_expiration.substring(0, 2),
					exp_year: this.card.card_expiration.substring(5, 9),
					cvc: this.card.card_cvc,
					coupon: this.user.coupon_code
				}
				
				axios.post('/payment', paymetFormData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							this.$auth.fetch().then(() => { this.$router.push({ name: 'procedures'}) })
							this.$emit("close_modal");
						}, 1000)
					}, 300)
				})
				.catch(error => {
					let errorData = error.response.data
					if(errorData.subscription && errorData.subscription.latest_invoice.payment_intent.status == 'requires_source_action'){
						var sub = errorData.subscription;

						Stripe(process.env.VUE_APP_STRIPEPUBLISHABLE_KEY)
						.confirmCardPayment(sub.latest_invoice.payment_intent.client_secret)
						.then((result) => {
							if(result.error){
								btnSubmitLoader.classList.add('finish')
								setTimeout(()=>{
									btnSubmitText.innerHTML = this.$t('btn-submit.error')
									btnSubmit.classList.add('error')
									btnSubmitLoader.classList.remove('onProgress', 'finish')
									btnSubmit.classList.remove('loading')
									setTimeout(()=>{
										btnSubmit.classList.remove('error')
										this.stripeError = result.error.message;
										btnSubmitText.innerHTML = this.$t('register.button-action-3')
									}, 1000)
								}, 300)
							}else{
								axios.post('/set-as-active')
								.then(({data}) => {
									btnSubmitLoader.classList.add('finish')
									setTimeout(()=>{
										btnSubmitText.innerHTML = this.$t('btn-submit.success')
										btnSubmit.classList.add('completed')
										btnSubmitLoader.classList.remove('onProgress', 'finish')
										btnSubmit.classList.remove('loading')
										setTimeout(()=>{
											btnSubmit.classList.remove('completed')
											this.$auth.fetch().then(() => { this.$router.push({ name: 'procedures'}) })
											this.$emit("close_modal");
										}, 1000)
									}, 300)
								})
							}
						});
					}else{
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('error')
								this.stripeError = error.response.data.message;
								btnSubmitText.innerHTML = this.$t('register.button-action-3')
							}, 1000)
						}, 300)
					}
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			}
		}
	}
</script>

<style lang="scss">
	.payment-modal{
		display: flex;
		align-items: flex-start;
		margin-left: -15px;
		margin-right: -15px;
		@media(max-width: 600px){
			flex-wrap: wrap;
		}
		.form-section{
			padding: 0 15px;
			@media(max-width: 600px){
				margin-left: 0 !important;
				margin-right: 0 !important;
				padding: 0;
			}
			.submit-form{
				padding: 0 15px;
				width: 100%;
				button{
					width: 100%;
				}
			}
		}
	}
	.package-selected{
		flex: 1 1 0;
		max-width: 50%;
		padding: 0 15px;
		@media(max-width: 600px){
			max-width: 100%;
			flex: 1 1 100%;
			margin-bottom: 30px;
		}
		.box{
			border-radius: 18px;
			border: 2px solid $borderColor;
			background: #FFF;
			overflow: hidden;
			height: 100%;
			display: flex;
			flex-direction: column;
			&.active{
				.header{
					.selected-check{
						display: flex;
					}
				}
				.list-features{
					.item-feature{
						.icon-square{
							background: $bgBlue;
							border: 1px solid $borderBlue;
							box-shadow: 0px 1px 4px rgba(8, 129, 255, 0.3);
							svg{
								.stroke-color{
									stroke: #FFF;
								}
							}
						}
					}
				}
			}
			.header{
				padding: 28px 24px 0;
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				.title{
					font-weight: 700;
					color: $primary;
					font-size: 16px;
					line-height: 24px;
				}
				.selected-check{
					display: none;
					margin-left: auto;
					height: 24px;
					width: 24px;
					border-radius: 50%;
					border: 1px solid $primary;
					align-items: center;
					justify-content: center;
					svg{
						width: 11px;
						height: auto;
						.stroke-color{
							stroke: $primary;
						}
					}
				}
			}
			.price-box{
				display: flex;
				align-items: flex-end;
				padding: 0 24px 22px;
				border-bottom: 2px solid $borderColor;
				height: 40px;
				.value{
					font-size: 22px;
					font-weight: 400;
					color: $primary;
					line-height: 1;
					.old-price{
						font-size: 18px;
						text-decoration: line-through;
						color: $placeholderColor;
					}
				}
				.period{
					font-size: 12px;
					color: #858792;
					margin-bottom: 4px;
					margin-left: 4px;
				}
				.custom-price{
					font-size: 14px;
					color: #858792;
					align-self: flex-start;
				}
			}
			.list-features{
				background: #FBFBFC;
				padding: 24px 24px;
				flex: 1;
				.item-feature{
					display: flex;
					align-items: center;
					margin-bottom: 12px;
					&:last-child{
						margin-bottom: 0;
					}
					.text{
						font-size: 12px;
					}
					.icon-square{
						display: flex;
						align-items: center;
						justify-content: center;
						margin-left: auto;
						height: 16px;
						width: 16px;
						border-radius: 4px;
						background: #FCFCFC;
						border: 1px solid $borderColor;
						svg{
							width: 8px;
							.stroke-color{
								stroke: $primary;
							}
						}
					}
				}
			}
			.action{
				background: #FBFBFC;
				padding: 0 24px 24px;
				.btn-tbf{
					width: 100%;
					justify-content: center;
				}
			}
		}
	}
</style>