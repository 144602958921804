<template>
	<div class="sidebar-org improvements">
		<div class="close-button" v-if="$resize && $mq.above(601)">
			<button class="btn-tbf white only-icon" @click="closeModal"><icon-close /></button>
		</div>
		<div class="content-sidebar" v-if="loaded">
			<div class="header">
				<div class="title">
					<div class="text">{{ $t('procedures.improvement_requests') }}</div>
				</div>
				<div class="action">
                    <div class="add-improvements" :class="{mobile: $resize && $mq.below(600)}" @click="createImprovement = true, $v.$reset()">{{$t('processes.add_request')}}</div>
					<div class="close-button" v-if="$resize && $mq.below(600)">
						<button class="btn-tbf white only-icon" @click="closeModal"><icon-close /></button>
					</div>
				</div>
			</div>

            <div v-if="createImprovement" class="info-content">
                <div class="box-improvements">
                    <div class="container-content-modal">
                        <div class="form-section">
                            <div class="input-group w-100">
                                <div class="label-header">
                                    <label class="label">{{ $t('enhancement.subject') }}*</label>
                                    <div v-if="$v.body.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                </div>
                                <div class="input-box bg-white" v-bind:class="{has_error: $v.body.$error}">
                                    <div class="icon-right" v-if="body != ''" @click.stop="body = ''"><icon-close class="icon-clear" /></div>
                                    <textarea id="subjectEnhancement" v-model="body" rows="5"></textarea>
                                </div>
                            </div>
                            <div class="input-group w-100">
                                <div class="label-header">
                                    <label class="label">{{ $t('enhancement.attachments') }}</label>
                                </div>
                                <div class="input-box bg-white">
                                    <div class="icon-left"><icon-file /></div>
                                    <input id="files-btn" type="file" multiple hidden v-on:change="onFilesChange">
                                    <label for="files-btn" class="label-file-btn">
                                        <div class="upload-text">{{ $t('enhancement.attach_files') }}</div>
                                        <div class="selected-files" v-if="filesName.length">- {{ filesName.length == 1 ? filesName[0] : $t('enhancement.more_files', {n : filesName.length}) }}</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
					<div class="form-submit improvements">
						<button class="btn-tbf white center" @click="createImprovement = false">
							<div class="loader"></div>
							<div class="text">{{$t('general.cancel')}}</div>
						</button>
						<button id="buttonSubmit" class="btn-tbf blue center" @click="submitEnhancement">
							<div class="loader"></div>
							<div class="text">{{ $t('general.submit') }}</div>
						</button>
					</div>
				</div>
			</div>

			<div v-if="improvementsData.improvements.length" class="list-improvements">
				<div class="improvement-item modal" v-for="improvement in improvementsData.improvements">
					<div class="top-data">
						<div class="left-data">
							<div class="user">
								<div class="image">
									<img :src="improvement.user.avatar" v-if="improvement.user.avatar">
									<div class="user-circle" v-else>
										<icon-user />
									</div>
									<div class="status">
										<div class="circle-status" v-bind:class="{green: improvement.status == 'active', blue: improvement.status == 'featuring', orange: improvement.status == 'archived'}"></div>
									</div>
								</div>
								<div class="data">
									<div class="tag">{{ improvement.created_at | moment("DD MMM YYYY") }}</div>
									<div class="name">{{ improvement.user.first_name + ' ' + (improvement.user.last_name ? improvement.user.last_name : '') }}</div>
								</div>
							</div>
							<div class="dropdown dropdown-status" v-if="improvementsData.rights.entity_crud">
								<div class="status" :ref="'dropdownEditRef'+improvement.id" :id="'dropdownEdit'+improvement.id" data-toggle="dropdown">
									<div class="text">{{$t('improvements.status.name')}}: <div class="value">{{ $t('improvements.status.' + improvement.status) }}</div></div>
								</div>

								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ improvement.id " :aria-labelledby="'dropdownEdit'+improvement.id">
									<div class="dropdown-item" v-if="improvement.status != 'active'" @click="showModal('change_improvement_status', {id: improvement.id, status: 'active', from: 'organigram'})">
										<div class="simple-text">{{ $t('improvements.action.activate') }}</div>
									</div>
									<div class="dropdown-item" v-if="improvement.status != 'archived'" @click="showModal('change_improvement_status', {id: improvement.id, status: 'archived', from: 'organigram'})">
										<div class="simple-text">{{ $t('improvements.action.archived') }}</div>
									</div>
									<div class="dropdown-item" v-if="improvement.status != 'featuring'" @click="showModal('change_improvement_status', {id: improvement.id, status: 'featuring', from: 'organigram'})">
										<div class="simple-text">{{ $t('improvements.action.featuring') }}</div>
									</div>
								</div>
							</div>
							<div class="dropdown-status" v-else>
								<div class="status">
									<div class="text">{{$t('improvements.status.name')}}: <div class="value">{{ $t('improvements.status.' + improvement.status) }}</div></div>
								</div>
							</div>
						</div>
						<div class="improvement-data">
							<div class="body" v-html="improvement.body"></div>
							<div class="files-data">
								<div class="file" v-for="file in improvement.files">
									<a class="file-box" :href="file.file_path" target="_blank">
										<div class="icon"><icon-file /></div>
										<div class="name">{{ file.filename }}</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row-space-tbf row-list-item" v-else-if="!createImprovement">
				<div class="content">
					<div class="column-empty-list">
						<div class="name"><img src="/build/images/no-results-found-inline.svg">{{ $t('improvements.empty-inline-improvements') }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconPlus from '../../Icons/Plus'
    import IconFile from "../../Icons/File"
    import IconUser from "../../Icons/User"
	import { required } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconClose,
			IconPlus,
            IconFile,
            IconUser
		},
		data() {
			return {
				loaded: false,
                createImprovement: false,
				improvementsData: {},
                body: '',
				attachments: '',
				filesName: []
			}
		},
		props:{
			data: Object
		},
        validations: {
			body: {required}
		},
		async mounted(){
			await this.getImprovements()

			this.$root.$on('refreshImprovementsProcesses', () => {
				this.getImprovements()
			})

			$(window).on("blur focus", (e) => {
				var prevType = $(this).data("prevType");
				if (prevType != e.type) {
					switch (e.type) {
						case "blur":
							this.timePaused = 'stop'
							break;
						case "focus":
							this.timePaused = 'start'
							break;
					}
				}

				$(this).data("prevType", e.type);
			})
		},
		methods: {
			async getImprovements(){
                await axios.get(`processes/${this.$route.params.slug}/improvement-proposals`)
				.then(({data}) => {
					this.improvementsData = data.data
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
            submitEnhancement(){
				var buttonName = `buttonSubmit`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.$v.$touch()
				if(!this.$v.$invalid){

					var objData = new FormData();
					objData.append('body', this.body)
					objData.append('procedure_id', this.data.type == 'procedures' ? this.data.id : '')
					objData.append('process_id', this.data.type == 'process' ? this.data.id : '')

					for( var i = 0; i < this.attachments.length; i++ ){
						let file = this.attachments[i];
						objData.append('files[' + i + ']', file);
					}

					axios.post('/improvement-proposals/store', objData)
					.then(({data}) => {
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('completed')
								btnSubmitText.innerHTML = this.$t('general.submit')
                                this.clearForm();
                                this.$v.$reset()
								if(this.data.type == 'procedures'){
									this.$root.$emit('refreshProcedureShow')
								} else if(this.data.type == 'process'){
									this.$root.$emit('refreshImprovementsProcesses')
								}
								this.createImprovement = false
							}, 1000)
						}, 300)
					})
					.catch(error => {
						console.log(error)
					})
					.finally(() => {
						setTimeout(()=>{
							btnSubmit.disabled = false
						}, 1500)
					})
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.submit')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			onFilesChange(e){
				this.filesName = []

				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.attachments = files

				files.forEach(el => {
					this.filesName.push(el.name)
				})
			},
            clearForm() {
                this.body = '',
				this.attachments = '',
				this.filesName = []
            },
			closeModal(){
				this.$emit("close_modal");
			},
            showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	}
</script>

<style lang="scss">
	.sidebar-org{
		.page-show-procedure{
            display: flex;
            flex-direction: column;
			border-top: 1px solid $borderColor;
			margin-top: 30px;
		}

		.column-empty-list{
			flex: 1;
			margin-top: 30px;
			.name{
				color: $grey;
				font-size: 14px;
				display: flex;
				align-items: center;
				line-height: 1;
				img{
					height: 25px;
					width: auto;
					margin-right: 5px;
				}
			}
		}
	}

    .list-empty {
        .content {
            &.improvements {
                border-top: none;
            }
        }
    }
    .box-improvements{
        border: 1px solid #F0F0F0;
        border-radius: 12px;
        background: #FDFDFD;
        padding: 30px;
        margin: 30px 0;
        @media(max-width: 1250px){
        	padding: 20px;
        }
        .form-section {
            padding-top: 0 !important;
        }
    }
    .add-improvements {
        font-size: 14px;
        text-decoration: underline;
        color: $bgBlue;
        cursor: pointer;
        &.mobile{
            position: absolute;
            top: 70px;
            width: 100px;
            left: 40px;
        }
        &:hover{
			color: #1889ff;
		}
    }
    .label-file-btn{
		height: 38px;
		padding: 0 45px;
		display: flex;
		align-items: center;
		.upload-text{
			display: flex;
			font-size: 12px;
			color: $primary;
			cursor: pointer;
			&:hover{
				text-decoration: underline;
			}
		}
		.selected-files{
			font-size: 12px;
			color: $grey;
			margin-left: 10px;
		}
	}

	.form-submit{
        &.improvements {
            padding-top: 0;
            button{
                width: 100%;
                min-width: initial;
            }
        }
	}
</style>