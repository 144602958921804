<template>
	<div class="modal-tbf modal-full-page" >
		<div class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">
					{{ $t('process-chart.add-new-procedure') }}
				</div>
				<div class="actions">
					<button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
							<div class="label-header">
								<label class="label">{{ $t('procedures.name') }}*</label>
							</div>
							<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
								<div class="icon-left"><icon-procedures /></div>
								<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
								<input type="text" :placeholder="$t('procedures.name_placeholder')" class="input-text" v-model="name">
							</div>
						</div>
						<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
							<div class="label-header">
								<label class="label">{{ $t('procedures.role') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-role /></div>
								<div class="icon-right" v-if="selectedRoles.length" @click.stop="selectedRoles = []"><icon-close class="icon-clear" /></div>
								<multiselect 
								class="hide-tags multiselect_checkbox"
								v-bind:class="{populate: selectedRoles.length}"
								v-model="selectedRoles"
								:options="optionsRoles"
								:allow-empty="true"
								:show-labels="false"
								:multiple="true" 
								:close-on-select="false"
								track-by="id" 
								label="name"
								>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('procedures.choose_role') }}
										</span>
									</template>
									<template slot="selection" slot-scope="{ values, search, isOpen }">
										<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
										<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
									</template>
									<template slot="option" slot-scope="props">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
										</div>
									</template>
									<template slot="noResult">{{ $t('general.no_result') }}</template>
									<template slot="noOptions">{{ $t('general.empty_list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
							<div class="label-header">
								<label class="label">{{$t('procedures.categories')}}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-category /></div>
								<div class="icon-right" v-if="selectedCategories.length" @click.stop="selectedCategories = []"><icon-close class="icon-clear" /></div>
								<multiselect 
								class="hide-tags multiselect_checkbox"
								v-bind:class="{populate: selectedCategories}"
								v-model="selectedCategories"
								:options="optionsCategories"
								:allow-empty="true"
								:show-labels="false"
								:multiple="true" 
								:close-on-select="false"
								track-by="id" 
								label="name"
								>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{$t('procedures.choose_category')}}
										</span>
									</template>
									<template slot="selection" slot-scope="{ values, search, isOpen }">
										<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
										<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
									</template>
									<template slot="option" slot-scope="props">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
										</div>
									</template>
									<template slot="noResult">{{ $t('general.no_result') }}</template>
									<template slot="noOptions">{{ $t('general.empty_list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
							<div class="label-header">
								<label class="label">{{$t('procedures.manager')}}*</label>
							</div>
							<div class="input-box" v-bind:class="{has_error: $v.selectedUser.$error}">
								<div class="icon-left"><icon-people /></div>
								<multiselect 
								v-model="selectedUser"
								:options="users"
								:allow-empty="false"
								:show-labels="false"
								:multiple="false" 
								:close-on-select="true"
								track-by="id"
								:custom-label="userCompleteName"
								label="first_name"
								>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{$t('procedures.choose_manager')}}
										</span>
									</template>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.first_name }} {{ option.last_name ? option.last_name : '' }}</template>
									<template slot="option" slot-scope="props">{{ props.option.first_name + ' ' + (props.option.last_name ? props.option.last_name : '') }}</template>
									<template slot="noResult">{{ $t('general.no_result') }}</template>
									<template slot="noOptions">{{ $t('general.empty_list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="saved-steps" v-if="savedSteps.length">
							<div class="label-header">
								<label class="label">{{ $t('procedures.steps_list') }}</label>
							</div>
							<div class="box">
								<draggable v-model="savedSteps">
									<div class="group-steps" v-for="(step,index) in savedSteps">
										<div class="name" v-html="step.body"></div>
										<div class="actions">
											<button class="btn-tbf white" @click="editStep(step, index)"><icon-edit /></button>
											<button class="btn-tbf white" @click="deleteStep(index)"><icon-trash /></button>
										</div>
									</div>
								</draggable>
							</div>
						</div>
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t('procedures.step') }}</label>
							</div>
							<div class="input-box" v-bind:class="{has_error: $v.body.$error}" style="overflow: hidden;">
								<quill-editor ref="myQuillEditor" v-model="body" :options="editorOption" />
							</div>
						</div>
						<div class="step-action">
							<div class="input-group input-group-btn" :class="[{'w-50': $resize && !$mq.above(601)}]">
							<div class="add-more-btn" >
								<button v-if="!isEditStep" class="btn-tbf blue" @click="addStep">
									<span class="text">{{ $t('procedures.add_step') }} </span>
								</button>
								<button v-else class="btn-tbf blue" @click="updateStep">
									<span class="text">{{ $t('procedures.update_step') }}</span>
								</button>
							</div>
							</div>
							<div v-if="isEditStep" class="input-group input-group-btn" :class="[{'w-50': $resize && !$mq.above(601)}]">
								<div class="add-more-btn" >
									<button class="btn-tbf grey" @click="isEditStep = false, body = ''">
										<span class="text">{{ $t('general.cancel') }} </span>
									</button>
								</div>
							</div>
						</div>
						
						<div class="saved-triggers" v-if="savedTriggers.length">
							<div class="label-header">
								<label class="label">{{ $t('procedures.triggers_list') }}</label>
							</div>
						<div class="box">
							<div class="group-skill" v-for="(trigger,index) in savedTriggers">
								<div class="main-label">
									<div class="name">{{ $t('trigger.' + trigger.type)}}</div>
									<div class="actions">
										<button class="btn-tbf white" @click="editTrigger(trigger, index)"><icon-edit /></button>
										<button class="btn-tbf white" @click="deleteTrigger(index)"><icon-trash /></button>
									</div>
								</div>
								<div class="level-skill">
									{{ trigger.type == 'procedure' ? getValue(trigger.value) : trigger.value}}
								</div>
							</div>
						</div>
					</div>

					<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{ $t('procedures.triggers') }}</label>
						</div>
						<div class="input-box bg-white" v-bind:class="{has_error: $v.selectedTrigger.$error}">
							<div class="icon-left"><icon-trigger /></div>
							<div class="icon-right" v-if="Object.values(selectedTrigger).length" @click.stop="selectedTrigger = []"><icon-close class="icon-clear" /></div>
							<multiselect 
								class="hide-tags"
								v-model="selectedTrigger"
								v-bind:class="{populate: Object.values(selectedTrigger).length}"
								:options="optionsTrigger"
								:allow-empty="false"
								:show-labels="false"
								:multiple="false" 
								:close-on-select="true"
								track-by="id" 
								label="name"
								@input="selectedProcedure = [], event_description = '', repetitive_date = '', $v.$reset()"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('procedures.choose_trigger') }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('create-tags.selected-users') }}</span></template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group input-group-btn w-50" v-if="$resize && $mq.above(600)">
						<div class="add-more-btn">
							<button v-if="!isEdit" class="btn-tbf blue" @click="addTrigger">
								<span class="text">{{ $t('procedures.add_trigger') }}</span>
							</button>
							<button v-else class="btn-tbf blue" @click="updateTrigger">
								<span class="text">{{ $t('procedures.update_trigger') }}</span>
							</button>
						</div>
					</div>
					<div class="input-group w-100" v-if="selectedTrigger.value == 'procedure'">
						<div class="label-header">
							<label class="label">{{ $t('procedures.procedures') }}</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedProcedure.$error}">
							<div class="icon-left"><icon-procedures /></div>
							<div class="icon-right" v-if="selectedProcedure.length" @click.stop="selectedProcedure = []"><icon-close class="icon-clear" /></div>
							<multiselect 
								class="hide-tags"
								v-bind:class="{populate: selectedProcedure.length}"
								v-model="selectedProcedure"
								:options="procedures"
								:allow-empty="true"
								:show-labels="false"
								:multiple="false" 
								:close-on-select="true"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('procedures.choose_procedure') }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
								</template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
					 <div v-if="selectedTrigger.value == 'date'" class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('procedures.select_date') }}</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.repetitive_date.$error}">
							<div class="icon-left"><icon-date /></div>
							<div class="icon-right"  v-if="repetitive_date" @click.stop="repetitive_date = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								class="hide-tags"
								v-bind:class="{populate: repetitive_date}"
								v-model="repetitive_date"
								:options="optionsDates"
								:allow-empty="false"
								:show-labels="false"
								:multiple="false" 
								:close-on-select="true"
								>
								<template slot="placeholder" slot-scope="props"><span class="text">{{$t('procedures.select_date')}}</span></template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>

							</multiselect>
						</div>
					</div>
					<div v-if="selectedTrigger.value == 'event'" class="input-group w-100">
						<div class="input-box bg-white" v-bind:class="{has_error: $v.event_description.$error}">
							<textarea rows="3" :placeholder="$t('procedures.event_placeholder')" v-model="event_description"></textarea>
						</div>
					</div>
					<div class="input-group input-group-btn w-100" v-if="$resize && $mq.below(599)">
						<div class="add-more-btn">
							<button v-if="!isEdit" class="btn-tbf blue" @click="addTrigger">
								<span class="text">{{ $t('procedures.add_trigger') }}</span>
							</button>
							<button v-else class="btn-tbf blue" @click="updateTrigger">
								<span class="text">{{ $t('procedures.update_trigger') }}</span>
							</button>
						</div>
					</div>

					</div>
					<div class="form-submit">
						<button class="btn-tbf grey center" @click="closeModal">
							<div class="loader"></div>
							<div class="text">{{$t('general.cancel')}}</div>
						</button>
						<button :id="edited ? 'buttonUpdate' : 'buttonCreate'" class="btn-tbf blue center" @click="edited ? saveFunction('update') : saveFunction('create')">
							<div class="loader"></div>
							<div class="text">{{ edited ? $t('procedures.update_procedure') : $t('procedures.new_procedure')}}</div>
						</button>
					</div>
				</div>
			</div>

			<div id="submodalContainer" class="submodal-container" v-if="subModalShow">
				<div class="overlay-submodal"></div>
				<modal-confirm-close id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
			</div>
		</div>
		<loader-procedures-create v-else />
	</div>

</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import IconPeople from '../Icons/People'
	import IconTrigger from '../Icons/Trigger'
	import IconProcedures from '../Icons/Procedures'
	import IconRole from '../Icons/Role'
	import IconDate from '../Icons/Date'
	import IconEdit from '../Icons/Edit'
	import IconTrash from '../Icons/Trash'
	import IconCategory from '../Icons/Category'
	import { required, requiredIf } from 'vuelidate/lib/validators'
	import draggable from 'vuedraggable'
	import ModalConfirmClose from '../Modals/ConfirmClose'
	import LoaderProceduresCreate from '../PagesLoaders/ProcedureModal'
	import Quill from 'quill'
	import ImageUploader from "quill-image-uploader/src/quill.imageUploader.js";

	Quill.register("modules/imageUploader", ImageUploader);

	export default {
		data() {
			return {
				loaded: false,
				edited: false,
				subModalShow: false,
				show_confirm_close: false,
				name: '',
				body: '',
				optionsRoles: [],
				selectedRoles: [],
				users: [],
				selectedUser: '',
				optionsTrigger:[
					{ name: this.$t('trigger.other_procedure'), value: 'procedure', id: 1 },
					{ name: this.$t('trigger.an_event'), value: 'event', id: 2 },
					{ name: this.$t('trigger.a_date'), value: 'date', id: 3 },
				],
				selectedTrigger: [],
				procedures: [],
				selectedProcedure: [],
				event_description: '',
				repetitive_date: '',
				optionsDates: Array.from({length: 31}, (_, i) => i + 1),
				savedTriggers: [],
				indexTrigger: '',
				isEdit: false,
				savedSteps: [],
				indexStep: '',
				isEditStep: false,
				stepValidation: false,
				triggerValidation: false,
				saveValidations: true,
				editorOption: {
					modules:{
						toolbar: [
							[{ 'header': [1, 2, 3, false] }],
							['bold', 'italic', 'underline', 'strike'],
							['blockquote', 'code-block'],
							[{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'align': [] }],
							[{ 'color': [] }, { 'background': [] }],
							['link', 'image'],
							['clean']
						]
					}
				},
				// selectedCategory: '',
				selectedCategories: [],
				optionsCategories: []
			}
		},
		props:{
			data: Object
		},
		components: {
			IconClose,
			IconPeople,
			IconProcedures,
			IconTrigger,
			IconRole,
			IconDate,
			IconEdit,
			IconTrash,
			IconCategory,
			draggable,
			ModalConfirmClose,
			LoaderProceduresCreate
		},
		validations() {
			if(this.stepValidation){
				return { 
					name: {required: false}, 
					body: {required}, 
					selectedUser: {required:false},
					selectedTrigger: {required: false},  
					selectedProcedure: {required: false}, 
					event_description: {required: false},
					repetitive_date: {required: false}
				}
			} else if(this.triggerValidation) {
				return { 
					name: {required: false}, 
					body: {required: false}, 
					selectedUser: {required:false},
					selectedTrigger: {required}, 
					selectedProcedure: {
						requiredIf: requiredIf( function() {
							return this.selectedTrigger.value == 'procedure'
						})
					},
					event_description: {
						requiredIf: requiredIf( function() {
							return this.selectedTrigger.value == 'event'
						})
					},
					repetitive_date: {
						requiredIf: requiredIf( function() {
							return this.selectedTrigger.value == 'date'
						})
					},
				}
			} else if(this.saveValidations) {
				return {
					name: {required},
					body: {
						requiredIf: requiredIf( function() {
							return this.savedSteps.length == 0
						})
					},
					selectedUser: {required},
					selectedTrigger: {required: false},
					selectedProcedure: {required: false},
					event_description: {required: false},
					repetitive_date: {required: false}
				}
			} else {
				return {
					name: {required: false},
					body: {required: false},
					selectedUser: {required:false},
					selectedTrigger: {required: false},
					selectedProcedure: {required: false},
					event_description: {required: false},
					repetitive_date: {required: false}
				}
			}
			
		},
		async mounted(){
			await this.getUsers();
			await this.getRoles()
			await this.getCategories()
			await this.getProcedures()

			this.$root.$on("confirm_close", () => {
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_confirm_close = true
					setTimeout(() => {
						document.getElementById('submodalContainer').classList.add('active')
						setTimeout(() => {
							document.getElementById('submodal_confirm_close').classList.add('active')
						}, 200);
					}, 0);
				}else{
					this.$emit("close_modal");
				}
			});
		},
		computed: {
			editor() {
				return this.$refs.myQuillEditor.quill
			}
		},
		methods: {
			image_upload_handler(blobInfo, success, failure) {
				success("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
			},
			userCompleteName ({ first_name, last_name }) {
				return `${first_name} ${last_name ? last_name : ''}`
			},
			closeModal(){
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_confirm_close = true
					setTimeout(() => {
						document.getElementById('submodalContainer').classList.add('active')
						setTimeout(() => {
							document.getElementById('submodal_confirm_close').classList.add('active')
						}, 200);
					}, 0);
				}else{
					this.$emit("close_modal");
				}
			},
			checkInputs(){
				var notEmpty = false

				if(this.name != '' || this.body != '' || this.savedSteps.length)
				{
					notEmpty = true
				}

				return notEmpty
			},
			closeModalConfirmed(){
				this.$emit("close_modal");
			},
			cancelClose(){
				document.getElementById('submodal_confirm_close').classList.remove('active')
				setTimeout(() => {
					document.getElementById('submodalContainer').classList.remove('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_confirm_close = false
					}, 200);
				}, 200);
			},
			async getUsers(){
				await axios.get(this.$auth.user().instance.id + '/users')
				.then((response) => {
					this.users = response.data.data;
				})
			},
			async getRoles(){
				await axios.get(this.$auth.user().master_instance_id + '/roles')
				.then(({data}) => {
					this.optionsRoles = data.data
				})
			},
			async getCategories(){
				await axios.get( this.$auth.user().master_instance_id + '/categories')
				.then(({data}) => {
					this.optionsCategories = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			async getProcedures(){
				await axios.get(this.$auth.user().instance.id + '/procedures')
				.then(({data}) => {
					this.procedures = data.data
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			getValue(value) {
				var filtered = this.procedures.filter((item)  => item.id == value)
				return filtered.map(el => el.name).toString()
			},
			addStep(){
				this.saveValidations = false
				this.triggerValidation = false
				this.stepValidation = true
				this.$v.$touch()
				if(!this.$v.$invalid){
					this.savedSteps.push({body: this.body})
					this.body = ''
					this.stepValidation = false
				}
			},
			editStep(step, index){
				this.body = step.body;
				this.indexStep = index;
				this.isEditStep = true
			},
			updateStep(){
				this.savedSteps[this.indexStep].body = this.body
				this.isEditStep = false
				this.body = ''
			},
			deleteStep(index){
				this.savedSteps.splice(index, 1);
			},
			addTrigger(){
				this.saveValidations = false
				this.triggerValidation = true
				this.stepValidation = false
				this.$v.$touch()
				if(!this.$v.$invalid){
					var triggerObj = {
						type: this.selectedTrigger.value,
						value: this.selectedTrigger.value == 'procedure' ? this.selectedProcedure.id : this.selectedTrigger.value == 'event' ? this.event_description : this.selectedTrigger.value == 'date' ? this.repetitive_date : ''
					}

					this.savedTriggers.push(triggerObj)
					this.triggerValidation = false
					this.clearTriggerForm();
				}
			},
			editTrigger(trigger, index){
				this.isEdit = true
				this.triggerSelected = trigger;
				this.indexTrigger = index
				this.selectedProcedure = []
				if(trigger.type == "procedure") {
					this.trigger_name = trigger.name
					this.selectedTrigger = { name: this.$t('trigger.other_procedure'), value: 'procedure', id: 1 }
					this.procedures.find(el => {
						if(el.id == trigger.value) {
							this.selectedProcedure = el
						}
					})
				} else if(trigger.type == "event") {
					this.trigger_name = trigger.name
					this.selectedTrigger = { name: this.$t('trigger.an_event'), value: 'event', id: 2 }
					this.event_description = trigger.value
				} else {
					this.trigger_name = trigger.name
					this.selectedTrigger = { name: this.$t('trigger.a_date'), value: 'date', id: 3 }
					this.repetitive_date = trigger.value
				}
			},
			deleteTrigger(index) {
				this.savedTriggers.splice(index, 1);
			},
			updateTrigger() {
				var triggerObj = {
					type: this.selectedTrigger.value,
					value: this.selectedTrigger.value == 'procedure' ? this.selectedProcedure.id : this.selectedTrigger.value == 'event' ? this.event_description : this.selectedTrigger.value == 'date' ? this.repetitive_date : ''
				}

				this.savedTriggers[this.indexTrigger] = triggerObj
				this.clearTriggerForm();
			},

			clearTriggerForm() {
				this.selectedTrigger = ''
				this.selectedProcedure = []
				this.event_description = ''
				this.repetitive_date = ''
				this.isEdit = false
			},
			saveFunction(type){
				this.saveValidations = true
				this.triggerValidation = false
				this.stepValidation = false

				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.$v.$touch()
				if(!this.$v.$invalid){
					if(this.body != '') {
						this.savedSteps.push({body: this.body})
						this.body = ''
						this.stepValidation = false
						
					}
					if((this.selectedTrigger.value == 'procedure' && this.selectedProcedure.id) || (this.selectedTrigger.value == 'event' && this.event_description != '') || (this.selectedTrigger.value == 'date' && this.repetitive_date)) {
						var triggerObj = {
							type: this.selectedTrigger.value,
							value: this.selectedTrigger.value == 'procedure' ? this.selectedProcedure.id : this.selectedTrigger.value == 'event' ? this.event_description : this.selectedTrigger.value == 'date' ? this.repetitive_date : ''
						}
						this.savedTriggers.push(triggerObj)
						this.triggerValidation = false
						this.clearTriggerForm();
					}
					var savedTriggers = this.savedTriggers.length ? this.savedTriggers.map(el => {return {id:el.id, type: el.type, value: el.type == 'procedure' ? JSON.stringify(el.value) : el.value}}) : ''
					
					var selectedCategories = this.selectedCategories.length ? this.selectedCategories.map(function(a) {return a.id;}) : ''

					let objData = {}
					objData.name = this.name
					objData.triggers = savedTriggers
					objData.steps = this.savedSteps
					objData.responsible_id = this.selectedUser.id
					
					var selectedRoles = this.selectedRoles.length ? this.selectedRoles.map(function(a) {return a.id;}) : ''
					if(selectedRoles.length) { objData.roles = selectedRoles }
					if(selectedCategories.length) { objData.categories = selectedCategories }

					if(type == 'create'){
						this.createProcedure(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateProcedure(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

					}else{
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('error')
								btnSubmitText.innerHTML = type == 'create' ? this.$t('procedures.new_procedure') : this.$t('procedures.update_procedure')
								btnSubmit.disabled = false
							}, 1000)
						}, 300)
					}
				},
				createProcedure(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
					axios.post('/procedures/store', objData)
					.then(({data}) => {
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('completed')
								btnSubmitText.innerHTML = this.$t('procedures.new_procedure')
								this.$emit("close_modal");
								this.$root.$emit('updateListProceduresChart', data.id);
							}, 1000)
						}, 300)
					})
					.finally(() => {
						setTimeout(()=>{
							btnSubmit.disabled = false
						}, 1500)
					})
				},
				updateProcedure(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
					objData.is_publish = this.data.version.publish
					axios.patch(`/process-versions/${this.data.version.id}`, objData)
					.then(() => {
						this.$emit("close_modal")
						this.$root.$emit('proccesNewVersion', false)
					})
				}
			}
		}
	</script>