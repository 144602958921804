<template>
<div class="modal-tbf modal-full-page modal-report-procedures-checks">
    <div class="container-modal opacity-page">
        <div class="header-modal">
            <div class="title">
                {{ $t('reports.procedures_checks') }}
            </div>
            <div class="actions">
                <button class="btn-tbf blue only-icon btn-excel center" @click="donwloadXLS">
                    <div class="loader-spin white" v-if="loadingExportXLS"></div>
                    <icon-download class="icon" v-else/>
                </button>
                <button class="btn-tbf white only-icon close-btn" @click="cancelModal"><icon-close class="icon-close" /></button>
            </div>
        </div>
        <div v-if="loaded" class="header-filter">
			<div class="row-space-tbf search-row no-padding-top">
				<!-- <icon-search v-if="$resize && $mq.above(781)"/> -->
				<div class="content">
                    <div class="search-input">
                        <input type="text" :placeholder="$t('general.search_by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
                    </div>
                    <div class="filter-btns">
						<date-range-picker
							ref="picker"
							class="calendar-filter"
                            :class="[{mobile: $resize && $mq.below(650)}]"
							opens="right"
							:locale-data="calendarFilterSettings"
							:singleDatePicker="$mq.below(650) ? 'range' : false"
							:showWeekNumbers="false"
							:showDropdowns="false"
							:ranges="false"
							:autoApply="true"
							:linkedCalendars="true"
							:maxDate="maxDateCalendar"
							v-model="dateRange"
							@update="updateRangeFilter"
							>
							<template v-slot:input="picker">
								<div class="input-picker">
                                    <div class="icon"><icon-date/></div>
									<div class="date" v-if="picker.startDate == picker.endDate">{{ picker.startDate | moment('DD MMM YYYY') }}</div>
									<div class="date" v-else>{{ picker.startDate | moment('DD MMM YYYY') }} - {{ picker.endDate | moment('DD MMM YYYY') }}</div>
									<icon-arrow class="icon-arrow right"/>
								</div>
							</template>
						</date-range-picker>
					</div>
				</div>
			</div>
		</div>
        <loader-modal-header-list v-else/>
        <template v-if="loadedList">
            <div class="data-list list-goals users" v-if="entries.length">
                <div class="row-space-tbf header-list">
                    <div class="content">
                        <div class="column-filter column-name">
                            <div class="text">{{ $t('general.name') }}</div>
                        </div>
                        <div v-if="$resize && $mq.above(501)" class="column-filter column-status">
                            <div class="text">{{ $t('reports.status') }}</div>
                        </div>
                        <div  v-if="$resize && $mq.above(700)" class="column-filter column-date">
                            <div class="text">{{ $t('general.last_update') }}</div>
                        </div>
                        <div v-if="$resize && $mq.above(501)" class="column-filter column-progress">
                            <div class="text">{{ $t('reports.progres') }}</div>
                        </div>
                    </div>
                </div>

                <template v-for="procedureCheck in entries">
                    <div class="row-space-tbf row-list-item" :key="`procedureCheckItem${procedureCheck.id}`">
                        <!-- <div class="space-left"><icon-people v-if="$resize && $mq.above(781)"/></div> -->
                        <div class="content">
                            <div v-if="$resize && $mq.above(781)" class="border-hover"></div>
                            <div class="column-name-desc column-avatar column-name">
                                <img :src="procedureCheck.user.avatar" v-if="data.type == 'procedure' && procedureCheck.user.avatar">
                                <div class="user-circle" v-else-if="data.type == 'procedure'">
                                    <icon-user />
                                </div>
                                <div class="text">
                                    <div class="name" v-if="data.type == 'procedure'">{{ procedureCheck.user.first_name + ' ' + (procedureCheck.user.last_name ? procedureCheck.user.last_name : '') }}</div>
                                    <div class="name" v-else>{{ procedureCheck.procedure.name }}</div>
                                </div>
                            </div>
                            <div v-if="$resize && $mq.above(501)" class="column-simple-text column-status">
                                <div class="text">{{ $t(`procedures_check.status.${procedureCheck.status}`) }}</div>
                            </div>
                            <div v-if="$resize && $mq.above(700)" class="column-simple-text column-date">
                                <div class="text">{{ procedureCheck.updated_at | moment('DD MMM YYYY') }}</div>
                            </div>
                            <div v-if="$resize && $mq.above(501)" class="column-value-progress column-progress">
                                <div class="top-data">
                                    <div class="current-value">
                                        <div class="value">{{ procedureCheck.total_checked_steps }}/{{ procedureCheck.total_steps }}</div>
                                    </div>
                                    <div class="deadline">{{ procedureCheck.percent }}%</div>
                                </div>
                                <div class="progress-bar-tbf">
                                    <div class="complete-progress-tbf" :class="{green: procedureCheck.completed}" :style="`width: calc(5px + ${procedureCheck.percent}%);`"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<div class="title">{{ $t('empty.title')}}</div>
				</div>
				<div class="space-right"></div>
			</div>
        </template>
        <loader-items-modal-list v-else/>

        <infinite-loading :identifier="infiniteId" @infinite="infiniteProcedureChecks" ref="infiniteLoadingEntriesProcedureChecks"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconPeople from '../Icons/People'
import IconSearch from '../Icons/Search'
import IconDate from '../Icons/Date'
import IconArrow from '../Icons/Arrow'
import IconUser from '../Icons/User'
import IconDownload from '../Icons/Download'
import LoaderItemsModalList from '../PagesLoaders/ItemsModalList'
import LoaderModalHeaderList from '../PagesLoaders/HeaderModalList'
import InfiniteLoading from 'vue-infinite-loading'

	export default {
		data() {
			return {
                loaded: false,
            	loadedList: false,
                maxDateCalendar: moment().format('YYYY-MM-DD'),
				dateRange: {
					startDate: moment().add(-30, 'days'),
					endDate: moment()
				},
				calendarFilterSettings: {
					firstDay: 1,
					format: 'DD-MM-YYYY',
					daysOfWeek:  moment.weekdaysMin(),
					monthNames: moment.monthsShort()
				},
                search_filter: '',
                page: 0,
                infiniteId: 1,
                entries: [],
                loadingExportXLS: false
			};
		},
        props: {
			data: Object
		},
        components: {
            IconClose,
            IconPeople,
            IconSearch,
            IconDate,
            IconArrow,
            IconUser,
            IconDownload,
            LoaderItemsModalList,
            LoaderModalHeaderList,
            InfiniteLoading
        },
		mounted() {
            this.$refs.infiniteLoadingEntriesProcedureChecks.status = 1
            this.$refs.infiniteLoadingEntriesProcedureChecks.$emit('infinite', this.$refs.infiniteLoadingEntriesProcedureChecks.stateChanger)
            
			setTimeout(() => {
				$('.opacity-page').addClass('show')
                this.loaded = true
			}, 0)
		},
        computed: {

        },
		methods: {
            searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.entries = []
				this.infiniteId += 1
			},
            infiniteProcedureChecks($state){
                var paramsCall = { view_more: this.page * 20}
                this.sendFilters(paramsCall)

                axios.get(`/reports/${this.data.slug}/${this.data.type == 'procedure' ? 'procedure-check' : 'user-procedure-check'}`, { params: paramsCall })
                .then(({data}) => {
                    if(data.data.length) {
                        this.page++
                        this.entries.push(...data.data)
                        setTimeout(() => { $state.loaded() }, 200)

                        if(data.data.length < 20){
                            $state.complete()
                        }
                    } else{
                        $state.complete()
                    }

                    this.loadedList = true
                }).catch(error => {
                    if(error.response) {
                        if(error.response.status == 500) {
                            alert(this.$t('error.500'))
                        }
                    }
                })
            },
            sendFilters(paramsCall){
                if(this.search_filter){
                    paramsCall.search = this.search_filter
                }

                if(this.dateRange.startDate && this.dateRange.endDate) {
                    paramsCall.start_date = moment(this.dateRange.startDate).format('DD-MM-YYYY');
                    paramsCall.end_date = moment(this.dateRange.endDate).format('DD-MM-YYYY');
                }
            },
            updateRangeFilter(obiec){
                this.loadedList = false
                this.page = 0
                this.entries = []
                this.infiniteId += 1
			},
			cancelModal(){
				this.$emit("close_modal");
			},
            donwloadXLS(){
                this.loadingExportXLS = true
                var showColumnsObj = ['status', 'updated_at', 'progress']

                if(this.data.type == 'procedure') {
                    showColumnsObj.unshift('user.name')
                } else {
                    showColumnsObj.unshift('procedure.name')
                }

                var paramsCall = {}
                this.sendFilters(paramsCall)
                paramsCall.type = 'xls'
                paramsCall.show_columns = showColumnsObj

                axios.get(`/reports/${this.data.slug}/${this.data.type == 'procedure' ? 'procedure-check' : 'user-procedure-check'}`, 
                {
                    params : paramsCall,
                    responseType: 'blob'
                })
                .then((response) => {

                    let blob = new Blob([response.data], { type:"application/octet-stream" })
                    let fileURL = window.URL.createObjectURL(blob);

                    let fileName = (this.data.type == 'procedure' ? this.$t('reports.procedures_checks_xls', {name: this.data.name}) : this.$t('reports.procedures_checks_users_xls', {name: this.data.name})) + '.xlsx';

                    let fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute( 'download', fileName );
                    document.body.appendChild(fileLink);

                    fileLink.click();
                })
                .finally(() => {
                    this.loadingExportXLS = false
                })
            }
		}
	};
</script>

<style lang="scss">
.modal-report-procedures-checks{ 
    .btn-excel{
        margin-right: 10px;
    }
    .column-avatar {
        &.column-name{
            .user-circle,
            img {
                margin-right: 20px;
                min-width: 36px;
            }
            .text{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }
    .column-date{
        flex: 0 0 140px;
        .text{
            margin-right: 10px;
        }
    }
    .column-progress{
        flex: 0 0 100px;
    }
    .column-name{
        flex: 1 1 auto;
        display: flex;
    }
    .column-status{
        flex: 0 0 120px;
    }

    .filter-btns{
        display: flex;
        align-items: center;
        .btn-default-tbf{
            height: 36px;
            font-size: 12px;
            font-weight: 500;
            color: $primary;
            padding: 0 15px;
            min-width: 110px;
            text-align: left;
            display: flex;
            align-items: center;
            .icon-arrow{
                margin-left: auto;
            }
            .clearFilter{
                margin-left: auto;
                height: 12px;
                margin-right: -2px;
            }
        }
        .calendar-filter{
            margin-left: 0px;
        }
        .filter-status{
            margin-left: 20px;
        }
    }
    .input-picker{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .icon{
            margin-right: 10px;
            justify-content: center;
            display: flex;
            svg{
                height: 16px;
                width: auto;
                .fill-color{
                    fill: $placeholderColor;
                }
            }   
        }
        .text{
            color:$placeholderColor
        }
        .date{
            flex: 1;
            font-size: 12px;
            color: $primary;
            text-transform: capitalize;
            line-height: normal;
        }
    }

    .row-space-tbf {
        &.no-padding-top {
            padding-top: 0 !important;
        }
    }

    .circle-status{
        height: 12px;
        width: 12px;
        min-width: 12px;
        border-radius: 50%;
        margin-right: 5px;
        margin-left: 15px;
        &.green{
            border: 1px solid #03AE58;
            background: #b5eed1;
        }
        &.red{
            background-color: #f7b8cf;
            border: 1px solid #eb1b68;
        }
    }
}
</style>