<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="title">{{ $t('improvements.confirm_title') }}</div>
		</div>
		<div class="description-modal">
			{{ $t('improvements.confirm_text') }}
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelChangeStatus">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="submitChangeStatusBtn" class="btn-tbf blue center" @click="submitChangeStatus">
				<div class="loader"></div>
				<div class="text">{{ $t('general.update') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		data() {
			return {
				loaded: false
			};
		},
		props: {
			data: Object
		},
		mounted() {

		},
		methods: {
			cancelChangeStatus(){
				this.$emit("close_modal");
			},
			submitChangeStatus(){
				var buttonName = `submitChangeStatusBtn`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				axios.post(`/improvement-proposals/${this.data.id}/set-status`, {status: this.data.status})
				.then(({data}) => {
					if(this.data.from == 'show'){
						this.$root.$emit('refreshProcedureShow')
					} else if(this.data.from == 'organigram') {
						this.$root.$emit('refreshImprovementsProcesses')
					} else{
						this.$root.$emit('refreshListImprovements')
					}

					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						if(this.data.from == 'organigram') {
							this.$root.$emit('change_modal', 'sidebar_improvements');
						} else {
							this.$emit("close_modal")
						}
					}, 1000)
				})
			}
		}
	};
</script>