<template>
<div class="modal-tbf modal-full-page">
    <div class="container-modal opacity-page">
        <div class="header-modal">
            <div class="title">
                {{$t('role.title')}}
            </div>
            <div class="actions">
                <button class="btn-tbf white only-icon close-btn" @click="cancelRole"><icon-close class="icon-close" /></button>
            </div>
        </div>

        <div class="info-content">
            <div class="container-content-modal">
                <div class="form-section">
                    <div class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{$t('general.name')}}*</label>
							<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
                        </div>
                        <div class="input-box bg-white role" v-bind:class="{has_error: $v.name.$error}">
                            <div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
                            <input type="text" :placeholder="$t('general.name_placeholder')" class="input-text no-icon" v-model="name">
                        </div>
                    </div>

                    <div class="input-group w-100">
                        <div class="input-box bg-white">
                            <textarea rows="3" :placeholder="$t('general.description_placeholder')" v-model="description"></textarea>
                        </div>
                    </div>
                </div>
                <div class="form-submit role">
                    <button class="btn-tbf grey center" @click="cancelRole">
                        <div class="loader"></div>
                        <div class="text">{{$t('general.cancel')}}</div>
                    </button>
                    <div class="error-msg" v-show="error_message!=''">{{error_message}}</div>
                    <button id='buttonCreateRole' class="btn-tbf blue center" @click="saveAction('create')">
                        <div class="loader"></div>
                        <div class="text">{{$t('general.add')}}</div>
                    </button>
                </div>
            </div>
        </div>
		<div id="submodalContainer" class="submodal-container" v-if="subModalShow">
			<div class="overlay-submodal"></div>
			<modal-confirm-close id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
		</div>
	</div>
</div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconPeople from '../Icons/People'
import { required } from 'vuelidate/lib/validators'
import ModalConfirmClose from '../Modals/ConfirmClose'
	export default {
		data() {
			return {
                name: '',
                description: '',
				subModalShow: false,
                show_confirm_close: false,
                error_message: '',
                errorsBe: {}
			};
		},
        components: {
            IconClose,
            IconPeople,
			ModalConfirmClose
        },
        validations: {
			name: {required},
		},
		mounted() {
			setTimeout(() => {
				$('.opacity-page').addClass('show')
			}, 0)

			this.$root.$on("confirm_close", () => {
                if(this.checkInputs()){
                    this.subModalShow = true
                    this.show_confirm_close = true
                    setTimeout(() => {
                        document.getElementById('submodalContainer').classList.add('active')
                        setTimeout(() => {
                            document.getElementById('submodal_confirm_close').classList.add('active')
                        }, 200);
                    }, 0);
                }else{
                    this.$emit("close_modal");
                }
            });
		},
		methods: {
			cancelRole(){
				this.$emit("close_modal");
			},
            saveAction(type){
				var buttonName = `buttonCreateRole`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

                this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
						name: this.name,
						description: this.description
					}
					if(type == 'create'){
						this.createRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            createRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/roles/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('btn-submit.create')
                            this.$emit("close_modal");
							this.$root.$emit('updateListProcedureCreate', data.role, false);
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			checkInputs(){
                var notEmpty = false

                if(this.name != ''){
                    notEmpty = true
                }

                return notEmpty
            },
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_confirm_close = false
                    }, 200);
                }, 200);
            },
		}
	};
</script>

<style lang="scss">
#modal_role {
    max-width: 500px;
    z-index: 10
}
div.input-box.bg-white.role > input.no-icon{
    padding: 0 45px 0 15px;
}
.form-submit.role > button{
    width: 50%;
}
</style>