<template>
    <div class="notifications" id="notificationsList" :class="{group: group, all: !group, start: first_time_loaded, 'to-back': goToGroup}" @click="showAllNotifications">
        <template v-for="(notification, index) in notifications.filter(el => el.seen == 0)">
            <div class="notification" :style="{'--index': index}" :id="notification.id">
                <div class="avatar">
                    <img :src="notification.avatar">
                </div>
                <div class="content-notification" @click="notification.need_link ?  viewItem(notification.entity_slug, notification, 'personal') : ''">
                    <p v-html="notification.name"></p>
                </div>
                <div class="close" @click="group ? clearAllNotifications() : removeNotification(notification)">
                    <icon-close/>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import IconClose from "../Icons/Close"

    export default {
        data() {
            return{
				notifications: [],
                countUnread: 0,
                group: false,
                first_time_loaded: true,
                goToGroup: false,
				objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK,
				organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK
            }
        },
        components: {
            IconClose
        },
        async mounted(){
			await this.getNotifications();

            this.$root.$on("updateNotificationsList", (parsedData) => {
				if(parsedData.for_personal){
					this.notifications.unshift(parsedData);
				}
			});
            
            window.addEventListener('click', (e) => {   
                if (!document.getElementById('notificationsList').contains(e.target)){
                     if(!this.group && this.group != null) {
                        this.goToGroup = true;
                        setTimeout(() => {
                            this.group = true
                            this.goToGroup = false;
                        }, 200)
                    }
                }
            });
        },
        methods: {
            async getNotifications(){
				await axios.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`).then(({data}) => {
					this.notifications = data.data.notification_logs
                    this.countUnread = data.data.count_unread
                    if(this.notifications.length > 3){
                        this.group = true
                    }

				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			viewItem(itemSlug, notification, column){
				if(notification.frontend_type == 'procedures'){
					this.$router.push({ name: 'procedure-show', params: { slug: itemSlug }})
				} else if (notification.frontend_type == 'processes') {
					this.$router.push({ name: 'process-show', params: { slug: itemSlug }})
                }else if(notification.frontend_type == 'tasks'){
					window.location.href = `${this.organigram_fe_link}/dashboard`
				}else if(notification.frontend_type == 'evaluations'){
					window.location.href = `${this.organigram_fe_link}/user/${itemSlug}`
				}else if (notification.frontend_type == 'announces') {
					window.location.href = `${this.organigram_fe_link}/announcements/${itemSlug}`
				}else {
                    window.location.href = `${this.objectives_fe_link}/obiectiv/${itemSlug}`
                }

				if(column == "personal"){ this.markAsRead(notification); }
			},
			markAsRead(notification){
				axios.patch(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notifications/${notification.id}`)
				.then(({data}) => {
					var contentNotification = document.querySelector(`#notification-box-${notification.id} .content-notification`)
					if(contentNotification){contentNotification.classList.remove('new')}
				});
			},
            removeNotification(notification){
                const element = document.getElementById(notification.id)
                element.remove()

                this.markAsRead(notification)
            },
            showAllNotifications() {
                this.first_time_loaded = false;
                this.group = false;
            },
            clearAllNotifications(){
                this.notifications.forEach(el => this.removeNotification(el));
            }
        }
    }
</script>
