import Vue from 'vue'

import auth                  from '@websanova/vue-auth/src/v2.js';
import driverAuthBearer      from '@websanova/vue-auth/src/drivers/auth/bearer.js';
import driverHttpAxios       from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';
import driverOAuth2Google    from '@websanova/vue-auth/src/drivers/oauth2/google.js';
import driverOAuth2Facebook  from '@websanova/vue-auth/src/drivers/oauth2/facebook.js';

driverOAuth2Google.params.client_id = '547886745924-4vrbhl09fr3t771drtupacct6f788566.apps.googleusercontent.com';
driverOAuth2Facebook.params.client_id = '196729390739201';

Vue.use(auth, {
    plugins: {
        http: Vue.axios,
        router: Vue.router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios, // Axios
        router: driverRouterVueRouter,
        oauth2: {
            google: driverOAuth2Google,
            facebook: driverOAuth2Facebook,
        }
    },
    options: {
        rolesKey: 'rights',
        notFoundRedirect: {name: 'not-exist'},
        rememberKey: 'auth_remember_digital',
        staySignedInKey: 'auth_stay_signed_in_digital',
        tokenDefaultKey: 'auth_token_digital',
        tokenImpersonateKey: 'auth_token_impersonate_digital',
        authRedirect: { path: '/login'},
        stores: ['cookie'],
        cookie: {
            Path: '/',
            Domain: process.env.VUE_APP_MAIN_DOMAIN,
            Expires: 12096,
            Secure: false,
            SameSite: 'Lax',
            'Max-Age': 2592000
        },
        logoutData: {
            redirect: '/login',
            makeRequest: true
        }
    }
});

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
Vue.component('date-range-picker', DateRangePicker)