<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/clone-duotone.svg"></div>
			<div class="title">{{ $t('clone.title') }}</div>
		</div>
		<div class="description-modal">
			{{ $t('clone.question', {text: $t('clone.type.' + data.type)}) }}
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelClone">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonSubmitClone" class="btn-tbf blue center" @click="storeClone">
				<div class="loader"></div>
				<div class="text">{{ $t('clone.submit') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				loaded: false
			};
		},
		props: {
			data: Object
		},
		mounted() {

		},
		methods: {
			cancelClone(){
				this.$emit("close_modal");
			},
			storeClone(){
				var buttonName = 'buttonSubmitClone'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				switch (this.data.type) {
					case 'procedure':
						this.cloneProcedure(btnSubmit, btnSubmitLoader, btnSubmitText)
						break;
					case 'process':
						this.cloneProcess(btnSubmit, btnSubmitLoader, btnSubmitText)
						break;
				}
			},
			cloneProcedure(btnSubmit, btnSubmitLoader, btnSubmitText){
				axios.post(`/procedures/${this.data.slug}/clone`)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							this.refreshPage()
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						this.$emit("close_modal")
					}, 1500)
				})
			},
			cloneProcess(btnSubmit, btnSubmitLoader, btnSubmitText){
				axios.post(`/processes/${this.data.slug}/clone`)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							this.refreshPage()
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						this.$emit("close_modal")
					}, 1500)
				})
			},
			refreshPage(){
				switch (this.data.from) {
					case 'procedures':
						this.$root.$emit('refreshProceduresIndex')
						break;
					case 'processes':
						this.$root.$emit('refreshProcessesIndex')
						break;
				}
			}
		}
	};
</script>