<template>
	<div class="modal-tbf modal-add-instance">
		<div id="opacityModal" class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">
					{{ $auth.user().rights.instance == 'new' ? $t('subscription.title_activation') : $t('subscription.title_renew') }}
				</div>
				<div class="actions" @click="closeModal">
					<div class="btn-tbf white only-icon close-btn"><icon-close class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="alert-tbf red" v-if="stripeError != ''">
						<div class="data">
							<div class="title">
								{{ stripeError }}
							</div>
						</div>
					</div>

					<div class="list-packages">
						<div class="item-package" v-for="subPackage,index in packagesList.filter(el => el.package_bundle == 'Single')" v-if="($auth.user().instance.status == 'grace_period' && subPackage.package_id == selectedPackage.package_id) || $auth.user().instance.status != 'grace_period'">
							<div class="box" v-bind:class="{active: subPackage.package_id == selectedPackage.package_id, 'error-selected': $v.selectedPackage.$error}" @click="selectPlan(subPackage)">
								<div class="header">
									<div class="title">{{subPackage.package_name}}</div>
									<div class="selected-check"><icon-check /></div>
								</div>
								<div class="price-box">
									<div class="value"><span class="old-price" v-if="subPackage.old_price_in_euro != subPackage.price_in_euro">{{ subPackage.old_price_in_euro }}</span> {{ newPrices.find((el) => el.package_id == subPackage.package_id) ? newPrices.find((el) => el.package_id == subPackage.package_id).price + ' EUR' : subPackage.price_in_euro + ' EUR' }}</div>
									<div class="period">/ {{ subPackage.interval == 'month' ? $t('general.monthly') : $t('general.yearly') }}</div>
								</div>
								<div class="list-features">
									<div class="item-feature" v-for="textFe in subPackage.includes">
										<div class="text">{{ textFe }}</div>
										<div class="icon-square"><icon-check /></div>
									</div>
								</div>
								<div class="action">
									<button class="btn-tbf" v-bind:class="[subPackage.package_id == selectedPackage.package_id ? 'blue' : 'white']" @click="selectPlan(subPackage)">
										<span class="text">{{ $t('subscription.select') }}</span>
									</button>
								</div>
							</div>
						</div>
					</div>

					<div class="form-section">
						<!-- Date facturare -->
						<div class="card-details">
							<div class="input-group w-100">
								<div class="label-header">
									<label class="label">{{$t('subscription.card_number')}}*</label>
									<div class="error-msg" v-if="cardErrors.cardNumber">{{ cardErrors.cardNumber }}</div>
								</div>
								<div class="input-box" v-bind:class="{has_error: $v.card.card_number.$error, complete: card.card_number != '' && !$v.card.card_number.$error}">
									<div class="icon-left"><icon-card /></div>
									<div class="icon-right" v-if="card.card_number != ''" @click.stop="card.card_number = ''"><icon-close class="icon-clear" /></div>
									<input type="text" placeholder="**** **** **** **** 2452" class="input-text" v-model="card.card_number" v-cardformat:formatCardNumber maxlength="19">
								</div>
							</div>
							<div class="input-group" :class="[{'w-100': $resize && $mq.below(500)}, {'w-50': $resize && $mq.between([501, 768])}, , {'w-60': $resize && $mq.above(769)}]">
								<div class="label-header">
									<label class="label">{{ $t('subscription.validity_date') }}*</label>
									<div class="error-msg" v-if="cardErrors.cardExpiry">{{ cardErrors.cardExpiry }}</div>
								</div>
								<div class="input-box" v-bind:class="{has_error: $v.card.card_expiration.$error, complete: card.card_expiration != '' && !$v.card.card_expiration.$error}">
									<div class="icon-left"><icon-date /></div>
									<div class="icon-right" v-if="card.card_expiration != ''" @click.stop="card.card_expiration = ''"><icon-close class="icon-clear" /></div>
									<input type="tel" placeholder="10 / 2030" class="input-text" v-model="card.card_expiration" v-cardformat:formatCardExpiry maxlength="9">
								</div>
							</div>
							<div class="input-group" :class="[{'w-100': $resize && $mq.below(500)}, {'w-50': $resize && $mq.between([501, 768])}, , {'w-40': $resize && $mq.above(769)}]">
								<div class="label-header">
									<label class="label">{{ $t('subscription.cvc') }}*</label>
									<div class="error-msg" v-if="cardErrors.cardCvc">{{ cardErrors.cardCvc }}</div>
								</div>
								<div class="input-box" v-bind:class="{has_error: $v.card.card_cvc.$error, complete: card.card_cvc != '' && !$v.card.card_cvc.$error}">
									<div class="icon-left"><icon-password /></div>
									<div class="icon-right" v-if="card.card_cvc != ''" @click.stop="card.card_cvc = ''"><icon-close class="icon-clear" /></div>
									<input type="tel" placeholder="***" class="input-text" v-model="card.card_cvc" v-cardformat:formatCardCVC maxlength="4">
								</div>
							</div>
						</div>

						<div class="coupon-box">
							<div class="input-group w-100">
								<div class="label-header">
									<label class="label">{{ $t('subscription.coupon.label') }}</label>
								</div>
								<div class="input-with-btn">
									<div class="input-box">
										<div class="icon-left"><icon-password /></div>
										<input type="text" v-model="coupon_code" :placeholder="$t('subscription.coupon.coupon-placeholder')" class="input-text">
									</div>
									<div class="btn-right">
										<button id="buttonCode" class="btn-tbf grey center" @click="checkCode">
											<div class="loader"></div>
											<div class="text">{{ $t('subscription.coupon.apply') }}</div>
										</button>
									</div>
								</div>
							</div>

							<div class="submit-form">
								<button id="buttonSubmitPayment" class="btn-tbf blue center" @click="submitPayment">
									<div class="loader"></div>
									<div class="text">{{$t('subscription.btn-pay')}}</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import IconCheck from '../Icons/Check'
	import IconPassword from '../Icons/Password'
	import IconCard	from '../Icons/Card'
	import IconDate from '../Icons/Date'
	import { required } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				selectedPackage: {},
				newPrices: [],
				card:{
					card_number: '',
					card_expiration: '',
					card_cvc: ''
				},
				cardErrors: {},
				stripeError: '',
				selectedPackage: {package_id: 0},
				packagesList: [],
				coupon_code: ''
			}
		},
		components: {
			IconClose,
			IconCheck,
			IconPassword,
			IconCard,
			IconDate
		},
		validations: {
			selectedPackage: {required},
			card: {
				card_number: {required},
				card_expiration: {required},
				card_cvc: {required}
			}
		},
		async mounted(){
			await this.getPackages();
		},
		methods: {
			checkUserVocuher(){
				if(this.$auth.user().voucher){
					this.coupon_code = this.$auth.user().voucher
					this.checkCode()
				}
			},
			closeModal(){
				this.$emit("close_modal");
			},
			async getPackages(){
				await axios.get('packages')
				.then(({data}) => {
					this.packagesList = data.data;
				})
				.finally(() => {
					if(this.$auth.user().instance){
						if(this.$auth.user().instance.package_id && this.packagesList.find(el => el.check_package_id == this.$auth.user().instance.package_id)){
							let currentPackage = this.packagesList.find(el => el.check_package_id == this.$auth.user().instance.package_id)
							this.selectedPackage = currentPackage
						}else{
							this.selectedPackage = {package_id: 0}
						}
					}

					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							document.getElementById('opacityModal').classList.add('show')
							setTimeout(() => {
								this.checkUserVocuher()
							}, 0)
						}, 0)
					}, 0)
				})
			},
			submitPayment(){
				var buttonName = 'buttonSubmitPayment'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true

				this.$v.$touch()

				this.cardErrors = {}
				this.stripeError = ''
				var cardError = false
				// validate card number
				if(!this.$cardFormat.validateCardNumber(this.card.card_number)){
					this.cardErrors.cardNumber = this.$t('validator.invalid_credit_card');
					cardError = true
				};

				// validate card expiry
				if (!this.$cardFormat.validateCardExpiry(this.card.card_expiration)) {
					this.cardErrors.cardExpiry = this.$t('validator.invalid_credit_card_date');
					cardError = true
				};

				// validate card CVC
				if (!this.$cardFormat.validateCardCVC(this.card.card_cvc)) {
					this.cardErrors.cardCvc = this.$t('validator.invalid_credit_card_cvc');
					cardError = true
				};

				if(this.$v.$invalid || cardError){
					btnSubmit.disabled = false
				}else{
					btnSubmit.classList.add('loading')
					btnSubmitLoader.classList.add('onProgress')
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')

					if((this.$auth.user().instance.package_id && (this.$auth.user().instance.package_id == this.selectedPackage.check_package_id)) || this.$auth.user().free_account){
						this.storePayment(btnSubmit, btnSubmitLoader, btnSubmitText)
					}else{
						this.changeSubscription(btnSubmit, btnSubmitLoader, btnSubmitText)
					}
				}
			},
			changeSubscription(btnSubmit, btnSubmitLoader, btnSubmitText){
				axios.post('/change-plan-account', {package_token: this.selectedPackage.token})
				.then(({data}) => {
					this.storePayment(btnSubmit, btnSubmitLoader, btnSubmitText)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('subscription.btn-pay')
						}, 1000)
					}, 300)
				})
			},
			storePayment(btnSubmit, btnSubmitLoader, btnSubmitText){
				let paymetFormData = {
					number: this.card.card_number,
					exp_month: this.card.card_expiration.substring(0, 2),
					exp_year: this.card.card_expiration.substring(5, 9),
					cvc: this.card.card_cvc,
					coupon: this.coupon_code
				}

				if(this.$auth.user().free_account) {
					paymetFormData.package_id = this.selectedPackage.package_id
				}
				
				axios.post('/payment', paymetFormData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							this.$emit("close_modal");
							this.$auth.fetch().then(() => { this.$router.push({ name: 'users'}) })
						}, 1000)
					}, 300)
				})
				.catch(error => {
					let errorData = error.response.data
					if(errorData.subscription && errorData.subscription.latest_invoice.payment_intent.status == 'requires_source_action'){
						var sub = errorData.subscription;

						let stripe = Stripe(process.env.VUE_APP_STRIPEPUBLISHABLE_KEY);
						stripe.confirmCardPayment(sub.latest_invoice.payment_intent.client_secret)
						.then((result) => {
							if(result.error){
								btnSubmitLoader.classList.add('finish')
								setTimeout(()=>{
									btnSubmitText.innerHTML = this.$t('btn-submit.error')
									btnSubmit.classList.add('error')
									btnSubmitLoader.classList.remove('onProgress', 'finish')
									btnSubmit.classList.remove('loading')
									setTimeout(()=>{
										btnSubmit.classList.remove('error')
										this.stripeError = result.error.message;
										btnSubmitText.innerHTML = this.$t('subscription.btn-pay')
									}, 1000)
								}, 300)
							}else{
								axios.post('/set-as-active')
								.then(({data}) => {
									btnSubmitLoader.classList.add('finish')
									setTimeout(()=>{
										btnSubmitText.innerHTML = this.$t('btn-submit.success')
										btnSubmit.classList.add('completed')
										btnSubmitLoader.classList.remove('onProgress', 'finish')
										btnSubmit.classList.remove('loading')
										setTimeout(()=>{
											btnSubmit.classList.remove('completed')
											this.$emit("close_modal");
											this.$auth.fetch().then(() => { this.$router.push({ name: 'users'}) })
										}, 1000)
									}, 300)
								})
							}
						});
					}else{
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('error')
								this.stripeError = error.response.data.message;
								btnSubmitText.innerHTML = this.$t('subscription.btn-pay')
							}, 1000)
						}, 300)
					}
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			checkCode(){
	            var buttonName = 'buttonCode'
	            var btnSubmit = document.getElementById(buttonName);
	            btnSubmit.disabled = true

	            var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
	            var btnSubmitText = document.querySelector(`#${buttonName} .text`)

	            btnSubmit.classList.add('loading')
	            btnSubmitLoader.classList.add('onProgress')
	            btnSubmitText.innerHTML = this.$t('btn-submit.loading')

	            this.errorCoupon = false

	            if(this.coupon_code != ''){
		            axios.post('validate-coupon', {coupon : this.coupon_code})
		            .then((data) => {
		                if(data.data.is_valid){
		                    btnSubmitLoader.classList.add('finish')
		                    setTimeout(()=>{
		                        btnSubmitText.innerHTML = this.$t('btn-submit.success')
		                        btnSubmit.classList.add('completed')
		                        btnSubmitLoader.classList.remove('onProgress', 'finish')
		                        btnSubmit.classList.remove('loading')
		                        setTimeout(()=>{
		                            btnSubmit.classList.remove('completed')
		                            btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-success')
		                            this.newPrices = data.data.values
		                        }, 1000)
		                    }, 300)
		                }else{
		                    btnSubmitLoader.classList.add('finish')
		                    setTimeout(()=>{
		                        btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
		                        btnSubmit.classList.add('error')
		                        btnSubmitLoader.classList.remove('onProgress', 'finish')
		                        btnSubmit.classList.remove('loading')
		                        setTimeout(()=>{
		                            btnSubmit.classList.remove('error')
		                            btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
		                            this.errorCoupon = true
		                            this.newPrices = []
		                            this.user.coupon_code = null;
		                        }, 1000)
		                    }, 300)
		                }
		            })
		            .catch(error => {
		                btnSubmitLoader.classList.add('finish')
		                setTimeout(()=>{
		                    btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
		                    btnSubmit.classList.add('error')
		                    btnSubmitLoader.classList.remove('onProgress', 'finish')
		                    btnSubmit.classList.remove('loading')
		                    setTimeout(()=>{
		                        btnSubmit.classList.remove('error')
		                        btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
		                        this.errorCoupon = true
		                        this.newPrices = []
		                        this.user.coupon_code = null;
		                    }, 1000)
		                }, 300)
		            })
		            .finally(() => {
		                setTimeout(()=>{
		                    btnSubmit.disabled = false
		                }, 1500)
		            })
	            }else{
	            	btnSubmitLoader.classList.add('finish')
	            	setTimeout(()=>{
	            		btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
	            		btnSubmit.classList.add('error')
	            		btnSubmitLoader.classList.remove('onProgress', 'finish')
	            		btnSubmit.classList.remove('loading')
	            		setTimeout(()=>{
	            			btnSubmit.classList.remove('error')
	            			btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
	            			this.errorCoupon = true
		                    btnSubmit.disabled = false
	            		}, 1000)
	            	}, 300)
	            }
	        },
			selectPlan(packageItem){
                this.selectedPackage = packageItem
			}
		}
	}
</script>

<style lang="scss">
	.modal-add-instance{
		.form-section{
			.card-details{
				max-width: 50%;
				flex: 1 1 0;
				display: flex;
				flex-wrap: wrap;
				@media(max-width: 600px){
					max-width: 100%;
					flex: 1 1 100%;
				}
			}
			.coupon-box{
				max-width: 50%;
				flex: 1 1 0;
				@media(max-width: 600px){
					max-width: 100%;
					flex: 1 1 100%;
				}
				.submit-form{
					padding: 24px 15px 0;
					@media(max-width: 600px){
						padding: 0 15px 0;
					}
					button{
						width: 100%;
					}
				}
			}
		}
	}
</style>