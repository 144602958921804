<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/procedures.svg"></div>
			<div class="title">{{ $t('procedures.publish') }}</div>
		</div>
		<div class="description-modal">
			{{ data.procedure.status == 'draft' ? $t('procedures.question_publish') :  $t('procedures.question_remove_publish') }}
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelPublish">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonSubmitPublish" class="btn-tbf blue center" @click="confirmPublish">
				<div class="loader"></div>
				<div class="text">{{ $t('general.submit') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				loaded: false
			};
		},
		props: {
			data: Object
		},
		mounted() {
		},
		methods: {
			cancelPublish(){
				this.$emit("close_modal");
			},
			confirmPublish(){
				var buttonName = 'buttonSubmitPublish'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.publishProcedure(btnSubmit, btnSubmitLoader, btnSubmitText)
			},
			publishProcedure(btnSubmit, btnSubmitLoader, btnSubmitText){
				var paramsApi = {}
				if(this.data.viewDraft){
					paramsApi = {status: 'live', source_steps: 'draft'}
				}else{
					if(this.data.procedure.status == 'draft'){
						paramsApi = {status: 'live', source_steps: 'live'}
					}else{
						paramsApi = {status: 'draft'}
					}
				}

				axios.post(`/procedures/${this.data.procedure.slug}/change-status`, paramsApi)
				.then(() => {
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$root.$emit('refreshProcedureShow', true)
							btnSubmit.classList.remove('completed')
						}, 1000)
					}, 300)
					setTimeout(()=>{
						this.$emit("close_modal")
					}, 1500)
				})
			}
		}
	};
</script>