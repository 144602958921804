<template>
	<div class="container-content-modal">
		<div class="form-section">
			<div class="input-group w-100">

				<div class="label-header">
					<label class="label">{{ $t('general.choose-color') }}*</label>
				</div>
				<div class="radio-colors">
					<div v-for="color in colors" class="box-colors">
						<div class="tag-color" v-bind:class="[ color ? `${color}` : '', {selected: selectedColor == color}]" @click="selectColor(color)">
							<span></span>
						</div>
					</div>
				</div>

				<div class="label-header">
					<label class="label">{{ $t('general.procedure') }}*</label>
					<div v-if="$v.selectProcedure.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.selectProcedure.$error}">
					<div class="icon-left"><icon-process /></div>
					<div class="icon-right" v-if="selectProcedure" @click.stop="selectProcedure = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						class="hide-tags"
						v-bind:class="{populate: selectProcedure}"
						v-model="selectProcedure"
						:options="optionsProcedures"
						:allow-empty="true"
						:max-height="120"
						:show-labels="false"
						:close-on-select="true"
						track-by="id" 
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('process-chart.modal.choose_procedure') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('general.no_result') }}</template>
						<template slot="noOptions">{{ $t('general.empty_list') }}</template>
					</multiselect>
				</div>
			</div>
		</div>
		<div class="form-submit">
			<button class="btn-tbf grey center" @click="changeSlide('main_form')">
				<div class="loader"></div>
				<div class="text">{{$t('general.cancel')}}</div>
			</button>
			<button id="buttonNextStep" class="btn-tbf blue center" @click="saveStep">
				<div class="loader-spin white" v-show="loadingSave"></div>
				<div class="text" v-show="!loadingSave">{{$t('process-chart.modal.add-procedure')}}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../../Icons/Close"
	import IconProcess from "../../Icons/Chart"
	import { required } from 'vuelidate/lib/validators'
	import IconCheck from "../../Icons/Check"

	export default {
		data() {
			return {
				loadingSave: false,
				selectProcedure: '',
				optionsProcedures: [],
                selectedColor: '',
				colors: [
					"grey",
					"purple",
					"blue",
					"green",
					"red",
					"yellow",
				]
			}
		},
		props:{
			dataId: Number
		},
		components: {
			IconClose,
			IconProcess,
			IconCheck
		},
		validations: {
			selectProcedure: {required}
		},
		async mounted(){
			await this.getProcedures()

			this.$root.$on('updateListProceduresChart', (data) => {
				this.getProcedures(data)				
			})
		},
		methods: {
			async getProcedures(selectedId = false){
				await axios.get(`instances/${this.$auth.user().instance.id}/filter`, { params: {procedures: true }})
				.then(({data}) => {
					this.optionsProcedures = data.data.procedures

					if(selectedId) this.selectProcedure = this.optionsProcedures.find(el => el.id == selectedId)
				})
			},
			changeSlide(slide){
				this.$emit('change_slide', slide)
			},
			selectColor(colorId){
				this.selectedColor = colorId
			},
			saveStep(){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true
				this.loadingSave = true

				this.$v.$touch()
				if(!this.$v.$invalid){
					this.$emit('store_step', {type: 'procedure', value: this.selectProcedure, color: this.selectedColor})
				}else{
					this.loadingSave = false
					btnSubmitText.innerHTML = this.$t('btn-submit.error')
					btnSubmit.classList.add('error')
					setTimeout(()=>{
						btnSubmit.classList.remove('error')
						btnSubmitText.innerHTML = this.$t('process-chart.modal.add-procedure')
						btnSubmit.disabled = false
					}, 1000)
				}
			},
			openCreateProcedure(){
				this.$root.$emit('open_modal', 'procedure')
			}
		}
	}
</script>