<template>
	<div class="modal-tbf modal-add-step">
		<div class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">
					{{ edited ? $t('process-chart.edit-version') : $t('process-chart.add-new-version') }}
				</div>
				<div class="actions">
					<button v-if="edited && !data.version.publish && $auth.user().rights.admins_crud && data.countVersions > 1" class="btn-tbf white only-icon trash-btn btn-space-mr" @click="showModal('delete', {type: 'version', from: 'modal_version', model: data})"><icon-trash class="icon-trash" /></button>
					<button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t('process-chart.version.title') }}*</label>
								<div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
							</div>
							<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
								<div class="icon-left"><icon-version /></div>
								<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
								<input type="text" :placeholder="$t('process-chart.version.placeholder_name')" class="input-text" v-model="name">
							</div>
						</div>
					</div>
					<div class="form-submit">
						<button class="btn-tbf grey center" @click="closeModal">
							<div class="loader"></div>
							<div class="text">{{$t('general.cancel')}}</div>
						</button>
						<button :id="edited ? 'buttonUpdate' : 'buttonCreate'" class="btn-tbf blue center" @click="edited ? saveFunction('update') : saveFunction('create')">
							<div class="loader"></div>
							<div class="text">{{ edited ? $t('general.update') : $t('general.add')}}</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import IconVersion from "../Icons/Version"
	import IconTrash from "../Icons/Trash"
	import { required } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				name: '',
				edited: false
			}
		},
		props:{
			data: Object
		},
		components: {
			IconClose,
			IconTrash,
			IconVersion
		},
		validations: {
			name: {required}
		},
		mounted(){
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
			
			if(this.data.hasOwnProperty('version')){
				this.edited = true
				this.name = this.data.version.name
			}
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			saveFunction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.$v.$touch()
				if(!this.$v.$invalid){
					
					let objData = {}
					objData.name = this.name
					objData.process_id = this.data.process_id
					objData.version_id = this.data.version_id
					
					if(type == 'create'){
						this.createVersion(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateVersion(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createVersion(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/process-versions/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('btn-submit.create')
							this.$emit("close_modal");
							this.$root.$emit('proccesNewVersion', data.id);
						}, 1000)
					}, 300)
				})
				.catch(error =>{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('btn-submit.create')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			updateVersion(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				objData.is_publish = this.data.version.publish

				axios.patch(`/process-versions/${this.data.version.id}`, objData)
				.then(() => {
					this.$emit("close_modal")
					this.$root.$emit('proccesNewVersion', false)
				})
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	}
</script>